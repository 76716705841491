<template>
  <div class="page-container-all width_1200_auto">
    <div>
      <a-image :preview="false" class="all-page-container" :src="require('@/assets/join/back.png')"></a-image>
    </div>
    <div class="page-join-content">
      <div class="page-container">
        <a-card :bordered="false" :body-style="{padding: '20px'}">
          <template #title>
            填写信息
          </template>
          <template #extra>
            <div class="extra cursor-pointer" @click="toJoinHis">申请记录</div>
          </template>
          <div class="pt-5 pl-10 pr-10">
            <a-form :model="merchantData" :label-col="{ style: { width: '150px' } }" :wrapper-col="{ span: 14 }"
                    @finish="checkPayPwd">
              <a-form-item label="商户名称" name="name"
                           :rules="[{ required: true, message: '请输入商户名称' }]">
                <a-input class="input-cell" :maxlength="16" v-model:value="merchantData.name"
                         :disabled="((merchantData.auditStatus && merchantData.auditStatus > 4) || merchantData.isPay)"
                         placeholder="请输入商户名称"></a-input>
              </a-form-item>
              <a-form-item label="商户登录用户名" name="realName"
                           :rules="[{ required: true, message: '请输入商户用户名' }]">
                <a-input class="input-cell" :maxlength="20" v-model:value="merchantData.realName"
                         @input="validateInput"
                         :disabled="((merchantData.auditStatus && merchantData.auditStatus > 4) || merchantData.isPay)"
                         placeholder="请输入商户用户名"></a-input>
              </a-form-item>
              <a-form-item label="电报" name="phone"
                           :rules="[{ required: true, message: '请输入真实电报链接,方便审核与您取得联系' }]">
                <a-input class="input-cell" @input="phoneInput" v-model:value="merchantData.phone"
                         :disabled="((merchantData.auditStatus && merchantData.auditStatus  > 4) || merchantData.isPay)"
                         placeholder="请输入真实电报链接,方便审核与您取得联系"></a-input>
              </a-form-item>
              <a-form-item :label="$t('pwd.pwd_003')" name="email"
                           :rules="[{ required: true, message: $t('pwd.pwd_004') },{ type: 'email', message: '请输入正确的邮箱' }]">
                <a-input class="input-cell" v-model:value="merchantData.email" :placeholder="$t('pwd.pwd_004')"
                         v-no-chinese
                         :disabled="((merchantData.auditStatus && merchantData.auditStatus  > 4) || merchantData.isPay)"
                ></a-input>
              </a-form-item>
              <a-form-item label="商户分类" name="categoryId"
                           :rules="[{ required: true, message: '请选择商户分类' }]">
                <a-select v-model:value="merchantData.categoryId"
                          show-search
                          placeholder="请选择商户分类"
                          :default-active-first-option="false"
                          :filter-option="false"
                          :not-found-content="null"
                          :disabled="((merchantData.auditStatus && merchantData.auditStatus  > 4) || merchantData.isPay)"
                          @change="handleChange">
                  <a-select-option v-for="(item, index) in $store.getters.merchantClassify" :key="index"
                                   :value="item.id">
                    <div class="">{{ item.name }}</div>
                    <div v-for="(items, indexs) in item.childList" :key="indexs" class="d-flex text-gray mt-1">
                      <div v-for="(itemss, indexs1) in items.childList" :key="indexs1" :class="indexs1 >0 ?'ml-2' : ''"
                           class="d-flex">
                        {{ itemss.name }}
                      </div>
                    </div>
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label=""
                           v-if="!merchantData.isPay && (merchantData.auditStatus === 1 ||  merchantData.auditStatus === 4 || merchantData.auditStatus === 3 ||!merchantData.auditStatus)">
                <a-button type="link" class="from-btn margin-150" html-type="submit">{{ $t('pwd.pwd_019') }}</a-button>
              </a-form-item>
              <a-form-item label="" name="checked"
                           v-if="!merchantData.isPay && (merchantData.auditStatus === 1 || merchantData.auditStatus === 4 || merchantData.auditStatus === 3 ||!merchantData.auditStatus)">
                <a-checkbox class="margin-150" v-model:checked="checked">已阅读并同意</a-checkbox>
                <span class="check-font cursor-pointer" @click="xYVisible = true">《商家入驻相关条款》</span>
              </a-form-item>
            </a-form>
          </div>
        </a-card>
      </div>

      <!-- 价格明细 -->
      <div class="margin-top-xs" style="width: 100%"
           v-if="((merchantData.auditStatus && merchantData.auditStatus === 2) || merchantData.isPay)">
        <a-card title="价格明细" :body-style="{padding: '20px'}">
          <a-form>
            <a-form-item label="店铺费用">
              <text class="text-gray">{{ merchantData.handlingFee + ' USDT' }}</text>
            </a-form-item>
            <a-form-item label="优惠券">
              <text class="text-gray">{{ merchantData.couponInfo ? merchantData.couponInfo : '无' }}
              </text>
            </a-form-item>
            <a-form-item label="实际支付金">
              <text class="text-red flex justify-end text-bold">{{ merchantData.factPrice + ' USDT' }}</text>
            </a-form-item>
          </a-form>
        </a-card>
      </div>

      <div class="page-container-info">
        <a-card :bordered="false" :body-style="{padding: '20px'}">
          <template #title>
            <a-image :preview="false" :src="require('@/assets/join/qa.png')" :width="32" :height="32"></a-image>
            常见问题
          </template>
          <a-collapse v-model:activeKey="activeKey" expand-icon-position="end" :bordered="false" ghost accordion>
            <a-collapse-panel v-for="(item, index) in issueList" :key="index">
              <template #header>
                <div class="d-flex align-center">
                  <div class="iss-item-coll-title">
                    <a-image :src="require('@/assets/issue.png')" :width="28" :height="22"></a-image>
                    <div class="text-coll">{{ 'Q' + (index + 1) }}</div>
                  </div>
                  <div class="ml-1">
                    {{ item.issue }}
                  </div>
                </div>
              </template>
              <div class="html-font" v-html="item.answer"></div>
            </a-collapse-panel>
          </a-collapse>
        </a-card>
      </div>
    </div>

    <a-modal v-model:open="xYVisible" title="商家入驻相关条款"
             :width="920"
             cancelText="取消">
      <template #footer>
        <a-button :type="'primary'" @click="cancel">确认</a-button>
      </template>
      <Scroll :height="750">
        <div v-html="protocol"></div>
      </Scroll>
    </a-modal>

    <ShopTip :show-model="showModel" :tips="tipsOne" @close="showModel = false"></ShopTip>
  </div>
</template>
<script>
import { getArticleDetails } from '@/api/api'
import { queryNormalIssue } from '@/api/tg'
import { getMerSettledApplyCheckApi } from '@/api/merchant'
import { Debounce } from '@/utils/validate'
import { merchantQuery } from '@/api/order'
import { isSet } from '@/api/user'
import ShopTip from '@/components/shopTip/index.vue'
import Payment from '@/components/payment/index.vue'

export default {
  components: {
    Payment,
    ShopTip
  },
  data() {
    return {
      checked: false,
      protocol: '',
      xYVisible: false,
      issueList: [],
      activeKey: '',
      prefix: 'https://t.me/',
      merchantData: {
        name: '',
        realName: '',
        email: '',
        phone: '',
        captcha: '',
        categoryId: null,
        handlingFee: '',
        keywords: '',
        qualificationPicture: '',
        typeId: 0,
        auditStatus: 0,
      },
      typeData: [],
      isKey: '',
      orderNo: '',
      showIndex: 0,
      tabCur: 0,
      showModel: false,
      tipsOne: '',
    }
  },
  created() {
    if (this.$store.getters.token) {
      this.getConfig()
      this.getIssueList()

      if (this.$route.query.key !== '1') {
        this.isKey = ''
      } else {
        this.orderNo = this.$route.query.orderNo
        this.isKey = this.$route.query.key
        this.queryOrderById(this.orderNo)
      }
    } else {
      this.$router.push({
        path: '/login',
      })
    }
  },
  methods: {
    validateInput(event) {
      let that = this
      let value = event.target.value
      // 使用正则表达式过滤非字母和数字字符
      value = value.replace(/[^A-Za-z0-9]/g, '')
      // 更新数据
      that.$nextTick(() => {
        that.merchantData.realName = value
      })
    },
    queryOrderById(id) {
      merchantQuery(id)
          .then((res) => {
            this.merchantData = res.data
            this.endPrice = this.merchantData.handlingFee
          })
    },
    toJoinHis() {
      this.$router.push({
        path: '/JoinHis',
      })
    },
    handleChange(e) {
      this.merchantData.categoryId = e
    },
    checkPayPwd() {
      isSet()
          .then((res) => {
            if (!res.data) {
              this.onFinish()
            } else {
              this.tipsOne = '未设置支付密码,前去设置?'
              this.showModel = true
            }
          })
    },
    onFinish: Debounce(function (e) {
      if (!this.checked) {
        return this.$Message.error('请确认入驻条款')
      }
      getMerSettledApplyCheckApi(this.merchantData)
          .then(res => {
            if (res.code === 200) {
              this.$router.push({
                path: '/JoinPay',
                query: {
                  id: res.data.id,
                },
              })
            } else {
              this.$Message.error(res.message)
            }
          })
    }),
    phoneInput(e) {
      this.$nextTick(() => {
        let inputValue = e.target.value
        let value = 'https://t.me'
        if (inputValue === value) {
          this.merchantData.phone = this.prefix
          return
        }
        value = 'https://t.m'
        if (inputValue === value) {
          this.merchantData.phone = this.prefix
          return
        }
        value = 'https://t.'
        if (inputValue === value) {
          this.merchantData.phone = this.prefix
          return
        }
        value = 'https://t'
        if (inputValue === value) {
          this.merchantData.phone = this.prefix
          return
        }
        value = 'https://'
        if (inputValue === value) {
          this.merchantData.phone = this.prefix
          return
        }
        value = '@'
        if (inputValue === value) {
          this.merchantData.phone = this.prefix
          return
        }
        const nonChineseValue = inputValue.replace(/[\u4e00-\u9fa5\s]/g, '')
            .replace(/@/g, '')
        if (nonChineseValue !== inputValue) {
          if (!nonChineseValue.startsWith(this.prefix)) {
            this.merchantData.phone = this.prefix + nonChineseValue
          } else {
            this.merchantData.phone = nonChineseValue
          }
        } else {
          if (!inputValue.startsWith(this.prefix)) {
            this.merchantData.phone = this.prefix + nonChineseValue
          } else {
            this.merchantData.phone = nonChineseValue
          }
        }
      })
    },
    cancel() {
      this.xYVisible = false
    },
    getConfig() {
      getArticleDetails(21)
          .then(res => {
            if (res.data) {
              this.protocol = res.data.content
            }
          })
    },
    getIssueList() {
      let data = {
        page: 1,
        limit: 100,
        issueType: 3
      }
      queryNormalIssue(data)
          .then(res => {
            this.issueList = res.data.list
          })
    },
  }
}
</script>

<style scoped lang="scss">
.extra {
  width: 84px;
  height: 26px;
  border-radius: 50px;
  background: rgba(68, 68, 68, 0.6);

  font-size: 12px;
  font-weight: normal;
  line-height: 25px;
  letter-spacing: 0px;
  color: #FFFFFF;
  text-align: center;
}

.html-font {
  font-size: 13px;
  font-weight: normal;
  color: #999999;
}

.margin-150 {
  margin-left: 150px;
}

.check-font {
  font-size: 14px;
  font-weight: normal;
  color: #FF6F4A;
}

.from-btn {
  width: 279px;
  height: 48px;
  border-radius: 8px;
  opacity: 1;
  background: linear-gradient(180deg, #FF834A 0%, #FF6F4A 100%);
}

.input-cell {
  height: 40px;
  border: 1px solid #E5E5E5;
}

.page-container-all {
  position: relative;
  margin-top: 3px;
  //width: 100%;
  min-height: 2020px;
  background: #80BFFF !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: flex-start;
  flex-direction: column;
  flex-wrap: nowrap;

  .all-page-container {
    position: absolute;
    top: 0;
    left: 0;
  }

  .page-join-content {
    position: absolute;
    top: 200px;
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: nowrap;

    .page-container {
      margin-top: 260px;
      width: 100%;
    }

    .page-container-info {
      margin-top: 20px;
      width: 100%;
    }

    .font-top1 {
      font-family: YS HelloFont BangBangTi;
      font-size: 80px;
      font-weight: normal;
      line-height: 25px;
      letter-spacing: 0px;
      color: #FFFFFF;
      text-shadow: 0px 6px 0px rgba(121, 14, 14, 0.6);
    }

    .font-top2 {
      font-family: YS HelloFont BangBangTi;
      font-size: 45px;
      font-weight: normal;
      line-height: 25px;
      letter-spacing: 0px;
      /* 全球合伙人 赚取 */
      color: #FFFFFF;
      margin-top: 60px;
    }

    .font-top3 {
      /* 高额佣金 */
      color: #FFF52E;
    }

    .page-join {
      margin-top: 30px;
      width: 132px;
      height: 38px;
      border-radius: 50px;
      opacity: 1;
      background: #FFCC00;

      display: flex;
      justify-content: center;
      align-items: center;

      .font-cus {
        font-size: 16px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: -0.17px;
        color: #3D3D3D;
      }
    }
  }

}
</style>
