<template>
  <div class="wrapper">
    <a-card class="cart-users" :bodyStyle="{padding: '20px'}">
      <div class="d-flex cu-center">
        <div class="me-auto ml-2" style="width: 520px;">
          <a-tabs v-model:activeKey="activeKey" @change="changeActive">
            <a-tab-pane :key="0" :tab="mesCount1 > 0 ? '全部(' +mesCount1+ ')' :'全部' "></a-tab-pane>
            <a-tab-pane :key="6" :tab="mesCount2 > 0 ? '系统消息(' +mesCount2+ ')' :'系统消息' "></a-tab-pane>
            <a-tab-pane :key="1" :tab="mesCount3 > 0 ? '订单通知(' +mesCount3+ ')' :'订单通知' "></a-tab-pane>
            <a-tab-pane :key="2" :tab="mesCount4 > 0 ? '交易记录(' +mesCount4+ ')' :'交易记录' "></a-tab-pane>
          </a-tabs>
        </div>
        <div class="cursor-pointer msg-btn" @click="selectedClick">
          全部已读 ({{ mesCount }})
        </div>
      </div>
      <a-card class="cart-users mb-2" :bodyStyle="{padding: '0', borderRadius:'0'}"
              v-for="(item, index) in msgList"
              :key="index"
              @click="setReadOnce(item)">
        <template #title>
          <div class="flex align-center justify-start">
            <a-image width="24px" height="24px" :src="item.type === 1 ? require('@/assets/info/qb.png') :
						item.type === 2 ? require('@/assets/info/yjj.png') :
						item.type === 3 ? require('@/assets/info/dabb.png') :
						item.type === 4 ? require('@/assets/info/qb.png') :
						item.type === 5 ? require('@/assets/info/rzz.png') :
						item.type === 6 ? require('@/assets/info/dd.png') : require('@/assets/info/tag.png')"/>
            <div class="ml-2">{{ item.title }}</div>
          </div>
        </template>
        <template #extra>
          <div class="cu-center cursor-pointer" @click="setReadOnce(item)">
            <Badge :dot="item.readFlag === 0">
              <span class="text-orange"></span>
            </Badge>
            <div class="text-sm text-gray ml-3">{{ item.updateTime }}</div>
          </div>
        </template>
        <div>
          <div class="pa-6 text-gray" v-html="item.message"></div>
          <!-- 提现 充值 -->
          <div class="text-right padding-lr-sm mb-2" v-if="item.type === 1"
               @click="loadOrderInfo(item)">
            <text class="cursor-pointer">
              查看详情
              <RightOutlined/>
            </text>
          </div>
          <!-- 担保 -->
          <div class="text-right padding-lr-sm mb-2" v-if="item.type === 3"
               @click="loadDanbaoInfo(item)">
            <text class="cursor-pointer">
              查看详情
              <RightOutlined/>
            </text>
          </div>
          <!-- 合伙人 -->
          <div class="text-right padding-lr-sm mb-2" v-if="item.orderNo && item.orderNo.startsWith('p-')"
               @click="loadPartnerInfo(item)">
            <text class="cursor-pointer">
              查看详情
              <RightOutlined/>
            </text>
          </div>
          <!-- 入驻 -->
          <div class="text-right padding-lr-sm mb-2" v-if="item.type === 5"
               @click="item.orderNo ? loadJoinMerInfo(item) : toJoinHis()">
            <text class="cursor-pointer">
              查看详情
              <RightOutlined/>
            </text>
          </div>
          <!-- 优惠券 -->
          <div class="text-right padding-lr-sm mb-2" v-if="item.title.indexOf('优惠券') > -1"
               @click="$router.push('/MyCoupon')">
            <text class="cursor-pointer">
              查看详情
              <RightOutlined/>
            </text>
          </div>
        </div>
      </a-card>
    </a-card>
    <!-- 分页 -->
    <div class="page-size margin-top-xs" v-if="msgList && msgList.length > 0">
      <a-pagination :page-size="limit" :total="total" show-less-items @change="changePageNum"
                    :show-size-changer="false"
                    @page-size-change="changePageSize"/>
    </div>
    <!-- 入驻 -->
    <a-modal v-model:open="joinVisible" :width="720" :bodyStyle="{borderTop: '1px solid #EEE'}">
      <template #title>
        <div>{{ selectOrderItem.title }}</div>
        <div class="text-sm text-gray">{{ selectOrderItem.updateTime }}</div>
      </template>
      <div class="flex flex-column justify-between pa-1 mt-3"
           v-if="orderInfo.fromUerId === '2' && selectOrderItem.title.indexOf('失败') <= -1">
        <div class="flex justify-between pa-1">
          <div class="text-df">商户后台链接</div>
          <div class="text-df cursor-pointer tg-name" @click="toUrl('https://adminmer.51danbao.com')">
            {{ 'https://adminmer.51danbao.com' }}
            <VerticalLeftOutlined/>
          </div>
        </div>

        <div class="flex justify-between pa-1">
          <div class="text-df">登录账户</div>
          <div class="text-df cursor-pointer tg-name" @click="$Copy({text: orderInfo.tgName})">
            {{ orderInfo.tgName }}
            <CopyOutlined/>
          </div>
        </div>

        <div class="flex justify-between pa-1">
          <div class="text-df">登录密码</div>
          <div class="text-df cursor-pointer tg-name" @click="$Copy({text: '000000'})">
            {{ '000000' }}
            <CopyOutlined/>
          </div>
        </div>

        <div class="pa-1 text-gray">
          尊敬的用户，恭喜您！您的商户入驻申请已经通过审核，您现在可以登录商户后台，开始管理您的店铺。请及时登录后台，开启您的店铺，开始您的商业旅程！
        </div>

      </div>
      <div class="flex flex-column justify-between pa-1 mt-3"
           v-if="orderInfo.fromUerId === '2' && selectOrderItem.title.indexOf('失败') > -1">
        <div class="pa-1">尊敬的用户，很遗憾地通知您，您的商户入驻申请未能通过审核。</div>
        <div class="pa-1">失败原因：
          <text class="text-red">[{{
              orderInfo.tgUrl ? orderInfo.tgUrl : '您已提交修改,请了解后续审核通知,谢谢!'
            }}]
          </text>
        </div>
        <div class="pa-1">我们建议您根据失败原因进行调整后重新提交申请。</div>
        <div class="pa-1">
          如有任何疑问或需要进一步了解详情，请随时联系平台客服，我们将提供必要的协助，帮助您顺利完成申请。感谢您的理解与支持，期待您的再次申请！
        </div>
      </div>
      <template #footer>
        <a-button type="primary" @click="joinCancel">{{ $t('common.com_000') }}</a-button>
      </template>
    </a-modal>
    <!-- 合伙人 -->
    <a-modal v-model:open="partnerVisible" :width="720" :bodyStyle="{borderTop: '1px solid #EEE'}">
      <template #title>
        <div>{{ selectOrderItem.title }}</div>
        <div class="text-sm text-gray">{{ selectOrderItem.updateTime }}</div>
      </template>
      <div class="flex flex-column justify-between pa-1 mt-3" v-if="selectOrderItem.orderNo.startsWith('p-suc')">
        <div class="pa-1">
          尊敬的用户，恭喜您！您的合伙人申请已成功通过审核，您现在正式成为我们的合伙人，并将享受以下推广计划收益：
        </div>
        <div class="pa-1">收益1：您将享有无限级用户入驻商家店铺返佣，每成功推荐一位商家入驻，您可获得 30 USDT 的返佣。</div>
        <div class="pa-1">收益2：您将享有无限级店铺交易手续费返佣，每笔交易的手续费将为您带来 30% 的返佣。</div>
      </div>
      <div class="flex flex-column justify-between pa-1 mt-3" v-else>
        <div class="pa-1">尊敬的用户，很遗憾地通知您，您的合伙人申请未能通过审核。</div>
        <div class="pa-1">失败原因：
          <text class="text-red">[{{ selectOrderItem.orderNo.split('-')[2] }}]</text>
        </div>
        <div class="pa-1">我们建议您根据失败原因进行调整后重新提交申请。</div>
        <div class="pa-1">
          如有任何疑问或需要进一步了解详情，请随时联系平台客服，我们将提供必要的协助，帮助您顺利完成申请。感谢您的理解与支持，期待您的再次申请！
        </div>
      </div>
      <template #footer>
        <a-button type="primary" @click="partnerCancel">{{ $t('common.com_000') }}</a-button>
      </template>
    </a-modal>
    <!-- 充值提款详情 -->
    <a-modal v-model:open="xYVisible" :title="typeTitleStr" :width="720">
      <div class="bg-white">
        <div class="flex flex-column justify-center align-center bg-white">
          <div class="margin-bottom-xs">
            <a-image :width="52" :height="52" :preview="false" :src="require('@/assets/info/USDT2.png')"></a-image>
          </div>
          <div class="text-xxl text-red">
            {{ inOut }} {{ orderInfo.amount }}
            <text class="ml-1 text-orange text-df">USDT</text>
          </div>
        </div>
        <a-divider></a-divider>
        <div class="flex justify-between pa-1">
          <div class="text-df">网络</div>
          <div class="text-df">TRC20</div>
        </div>
        <div class="flex justify-between pa-1">
          <div class="text-df">类型</div>
          <div class="text-df">{{ orderInfo.typeStr }}</div>
        </div>
        <div class="flex justify-between pa-1">
          <div class="text-df">状态</div>
          <div class="text-df">{{ orderInfo.successStr }}</div>
        </div>
        <div class="flex justify-between pa-1">
          <div class="text-df">充币地址</div>
          <div class="text-df cursor-pointer tg-name" @click="$Copy({text:orderInfo.address})">{{ orderInfo.address }}
            <CopyOutlined/>
          </div>
        </div>
        <div class="flex justify-between pa-1" v-if="orderInfo.txHash">
          <div class="text-df" style="width: 340rpx;">区块链交易ID</div>
          <div class="text-df cursor-pointer tg-name" @click="$Copy({text: orderInfo.txHash})">{{ orderInfo.txHash }}
            <CopyOutlined/>
          </div>
        </div>
        <div class="flex justify-between pa-1">
          <div class="text-df">订单号</div>
          <div class="text-df cursor-pointer tg-name" @click="$Copy({text: orderInfo.orderNo})">{{ orderInfo.orderNo }}
            <CopyOutlined/>
          </div>
        </div>
        <div class="flex justify-between pa-1">
          <div class="text-df">时间</div>
          <div class="text-df">{{ orderInfo.orderTime }}</div>
        </div>
        <a-divider></a-divider>
        <div class="text-df ml-2 text-gray">
          如有任何疑问或对账单有疑虑，请随时联系我们的在线客服，我们将为您提供帮助与支持!
        </div>
      </div>
      <template #footer>
        <a-button type="primary" @click="cancel">{{ $t('common.com_000') }}</a-button>
      </template>
    </a-modal>
    <!-- 担保 -->
    <a-modal v-model:open="dbVisible" :width="720" :bodyStyle="{borderTop: '1px solid #EEE'}">
      <template #title>
        <div>{{ typeTitleStr }}</div>
        <div class="text-sm text-gray">{{ typeTitleTime }}</div>
      </template>
      <div class="flex justify-between pa-1 mt-3">
        <div class="text-df">订单号</div>
        <div class="text-df cursor-pointer tg-name" @click="$Copy({text: orderInfo.orderNo})">
          {{ orderInfo.orderNo }}
          <CopyOutlined/>
        </div>
      </div>

      <div class="flex justify-between pa-1" v-if="$store.getters.userInfo.invitationCode !== orderInfo.fromUerId">
        <div class="text-df">发起人ID</div>
        <div class="text-df cursor-pointer tg-name" @click="$Copy({text: orderInfo.fromUerId})">
          {{ orderInfo.fromUerId }}
          <CopyOutlined/>
        </div>
      </div>

      <div class="flex justify-between pa-1">
        <div class="text-df">担保群名称</div>
        <div class="text-df cursor-pointer tg-name" @click="toUrl(orderInfo.tgUrl)">
          {{ orderInfo.tgName }}
          <VerticalLeftOutlined/>
        </div>
      </div>

      <div class="flex justify-between pa-1">
        <div class="text-df">工作人员用户名</div>
        <div class="flex">
          <div class="text-df ml-2" v-for="(item, index) in orderInfo.tgUserNames" :key="index">
            <div class=" cursor-pointer tg-name" @click="toUrl(item)">{{ item.replace('https://t.me/', '@') }}</div>
          </div>
        </div>
      </div>

      <div class="flex justify-between pa-1">
        <div class="text-df">唯一上押地址</div>
        <div class="text-df cursor-pointer tg-name" @click="$Copy({text: orderInfo.upAddress})">
          {{ orderInfo.upAddress }}
          <CopyOutlined/>
        </div>
      </div>
      <div class="text-df ml-1 text-gray mt-3" style="border-top: 1px solid #EEE;padding-top: 10px;">
        请认准群内工作人员，并按照指引完成后续操作。如有任何疑问，请随时联系平台客服。感谢您的信任与支持！
      </div>
      <template #footer>
        <a-button type="primary" @click="dbCancel">{{ $t('common.com_000') }}</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import {
  getMsgs,
  getMsgsDbInfo,
  getMsgsOrderInfo,
  getMsgsJoinInfo,
  getReadAll,
  getReadCountByType,
  getReadMsgs,
  getReadOnce,
  msgCusList,
  readCountByMes,
} from '@/api/message.js'
import FingerprintJS from '@fingerprintjs/fingerprintjs'

import { CopyOutlined, RightOutlined, VerticalLeftOutlined } from '@ant-design/icons-vue'

export default {
  name: 'Message',
  components: {
    RightOutlined,
    CopyOutlined,
    VerticalLeftOutlined,
  },
  data() {
    return {
      selected: 0,
      activeKey: 0,
      mesCount: '',
      readMsgs: [],
      mesCount1: '',
      mesCount2: '',
      mesCount3: '',
      mesCount4: '',
      page: 1,
      limit: 10,
      total: 0,
      msgList: [],
      messages: [],
      typeTitleStr: '',
      typeTitleTime: '',
      orderNo: '',
      inOut: '+',
      orderInfo: {},
      currentIndex: 0,
      xYVisible: false,
      dbVisible: false,
      partnerVisible: false,
      selectOrderItem: {},
      joinVisible: false,
    }
  },
  created() {
    this.getAllReadCount()
    this.getAll()
    this.getReadMsgsInfo()
    this.upCallback()
    this.queryMsgCusList()
  },
  watch: {
    '$store.getters.readCount': {
      handler(val) {
        if (this.$store.getters.token) {
          this.getAllReadCount()
          this.getAll()
          this.queryMsgCusList()
        }
      }
    }
  },
  methods: {
    loadJoinMerInfo(item) {
      this.selectOrderItem = item
      this.orderInfo = {}
      getMsgsJoinInfo(item.orderNo)
          .then(res => {
            this.orderInfo = res.data
            this.joinVisible = true
          })
    },
    toJoinHis() {
      this.$router.push({
        path: '/JoinHis',
      })
    },
    joinCancel() {
      this.joinVisible = false
      this.$router.push({
        path: '/JoinHis',
      })
    },
    partnerCancel() {
      this.partnerVisible = false
      this.$router.push({
        path: '/Partner',
      })
    },
    loadPartnerInfo(item) {
      this.selectOrderItem = item
      this.partnerVisible = true
    },
    toUrl(url) {
      window.open(url, '_blank')
    },
    loadDanbaoInfo(item) {
      this.orderInfo = {}
      this.typeTitleTime = item.updateTime
      this.typeTitleStr = item.title
      getMsgsDbInfo(item.orderNo)
          .then(res => {
            this.orderInfo = res.data
            this.dbVisible = true
          })
    },
    dbCancel() {
      this.dbVisible = false
    },
    loadOrderInfo(item) {
      this.orderInfo = {}
      this.typeTitleStr = ''
      getMsgsOrderInfo(item.orderNo)
          .then(res => {
            this.orderInfo = res.data
            this.inOut = this.orderInfo.msgStr.indexOf('充值') > -1 ? '+ ' : this.orderInfo.txHash ? '- ' : '+ '
            this.typeTitleStr = this.orderInfo.msgStr.indexOf('充值') > -1 ? '充值详情' : '提现详情'
            this.xYVisible = true
          })
    },
    cancel() {
      this.xYVisible = false
    },
    showIM(item) {
      let routeUrl = this.$router.resolve({
        path: '/ChatIm',
        query: { //要传的参数 可传多个
          dbMerId: item.merId
        }
      })
      window.open(routeUrl.href, '_blank')
    },
    changePageNum(val) { // 修改页码
      this.page = val
      this.upCallback()
    },
    changePageSize(val) { // 修改页数
      this.page = 1
      this.limit = val
      this.upCallback()
    },
    setReadOnce(item) {
      if (item.readFlag === 1) {
        this.extractUrls(item.message)
        return
      }
      let data = {
        id: item.id
      }
      getReadOnce(data)
          .then(res => {
            this.msgList = []
            this.page = 1
            this.getAllReadCount()
            this.upCallback()
            this.extractUrls(item.message)
          })
          .catch(err => {
          })
    },
    extractUrls(message) {
      // 正则表达式用于匹配URLs
      const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi
      let url = message.match(urlRegex)
      if (url) {
        window.open(message.match(urlRegex))
      }
    },
    upCallback() {
      let pageNum = this.page // 页码, 默认从1开始
      let pageSize = this.limit // 页长, 默认每页10条
      let data = {
        id: this.activeKey,
        itemId: this.itemId,
        page: pageNum,
        limit: pageSize
      }
      getMsgs(data)
          .then((res) => {
            this.total = res.data.total
            this.msgList = res.data.list
          })
    },
    getAll() {
      this.getReadMsgsInfo()
      this.readCountByType(0)
      this.readCountByType(6)
      this.readCountByType(1)
      this.readCountByType(2)
    },
    queryMsgCusList() {
      FingerprintJS.load()
          .then(fp => {
            fp.get()
                .then(result => {
                  let visitorId = result.visitorId
                  let data = {
                    headImg: visitorId,
                  }
                  msgCusList(data)
                      .then(res => {
                        this.messages = res.data
                        let msgCountIm = 0
                        if (this.messages && this.messages.length > 0) {
                          this.messages.forEach(item => {
                            msgCountIm += item.mesCount
                          })
                        }
                        this.mesCount3 = msgCountIm
                      })
                })
          })
    },
    readCountByType(typeId) {
      let para = {
        id: typeId
      }
      getReadCountByType(para)
          .then(res => {
            if (typeId === 0) {
              this.mesCount1 = res.data
            }
            if (typeId === 6) {
              this.mesCount2 = res.data
            }
            if (typeId === 1) {
              this.mesCount3 = res.data
            }
            if (typeId === 2) {
              this.mesCount4 = res.data
            }
          })
    },
    getReadMsgsInfo() {
      getReadMsgs()
          .then(res => {
            this.readMsgs = res.data
          })
    },
    getAllReadCount() {
      readCountByMes()
          .then(res => {
            this.mesCount = res.data
            this.$store.commit('NO_READ_COUNT', this.mesCount)
          })
    },
    changeActive(e) {
      this.activeKey = e
      this.msgList = []
      this.page = 1
      this.upCallback()
    },
    selectedClick(index) {
      this.selected = index
      getReadAll()
          .then(res => {
            this.mesCount = 0
            this.page = 1
            this.getAllReadCount()
            this.upCallback()
            this.getAll()
            this.queryMsgCusList()

            this.$store.commit('NO_READ_COUNT', this.mesCount)
          })
    }
  }
}
</script>

<style scoped lang="scss">
.tg-name {
  color: #333333;
}

.tg-name:hover {
  color: #2b85e4 !important;
}

.msg-btn {
  font-size: 14px;
  font-weight: 350;
  line-height: 25px;
}

.wrapper {
  width: 100%;

  .cart-users {
    width: 100%;
    border-radius: 12px;
    opacity: 1;
  }
}
</style>
