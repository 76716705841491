<template>
  <div>
    <div class="container width_1200">
      <div>
        <a-image :width="1200" :height="158" :src="require('@/assets/supply/banner.jpg')" :preview="false"></a-image>
      </div>
      <div class="flex justify-space-between" style="padding: 0 12px;">
        <a-tabs class="me-auto" v-model:activeKey="current" @change="selectedKeys">
          <a-tab-pane key="1">
            <template #tab><span class="item-font">供应</span></template>
          </a-tab-pane>
          <a-tab-pane key="2">
            <template #tab><span class="item-font">需求</span></template>
          </a-tab-pane>
        </a-tabs>

        <div class="cu-center">
          <a-button class="item-item-btns cu-center" type="link" @click="toAdd">
            新增
          </a-button>
          <a-button class="item-item-btn cu-center ml-4" type="link" @click="() => {$router.push({path: '/MySupply'})}">
            我的供需
          </a-button>
        </div>
      </div>

      <empty v-if="noteRecommendList.length === 0"/>

      <div style="margin-top: -20px;" v-else>
        <a-row :gutter="16">
          <a-col :span="6" class="list-g-x" v-for="(item, index) in noteRecommendList" :key="index">
            <a-card :bordered="false" :bodyStyle="{padding: '10px',height:'130px'}" @click="viewCard(item)"
                    class="cursor-pointer">
              <div class="list-title line2">
                <div class="d-flex">
                  <div class="title-tg" :class="current === '2' ? 'bg-blue' :''">
                    {{ current === '1' ? $t('i_015') : $t('i_016') }}
                  </div>
                  <div class="line1" style="width: 200px;">
                  {{ item.title }}
                  </div>
                </div>
              </div>
              <div class="list-content line2">{{ item.content }}</div>
              <div class="list-time">发布时间:{{ item.createTime }}</div>
            </a-card>
          </a-col>
        </a-row>
      </div>
      <!-- 分页 -->
      <div v-if="noteRecommendList && noteRecommendList.length > 0" class="page-size mb-3">
        <a-pagination :page-size="limit" :total="total" :show-size-changer="false" show-less-items
                      @change="changePageNum" @page-size-change="changePageSize"/>
      </div>
    </div>
  </div>
</template>

<script>
import { discoverListApi, } from '@/api/discover.js'

export default {
  data() {
    return {
      current: '1',
      activeGxKey: 1,
      page: 1,
      limit: 24,
      total: null,
      noteRecommendList: [],
    }
  },
  mounted() {
    this.getDiscoverList()
  },
  methods: {
    toAdd() {
      if (this.$store.getters.uid) {
        this.$router.push({
          path: '/AddSupply',
          query: {}
        })
      } else {
        this.$router.push({
          path: '/Login',
          query: {}
        })
      }
    },
    viewCard(item) {
      this.$router.push({
        path: '/SupplyInfo',
        query: {
          id: item.id,
        }
      })
    },
    //发现列表
    getDiscoverList() {
      this.where = {
        page: this.page,
        limit: this.limit,
        categoryId: this.activeGxKey,
      }
      discoverListApi(this.where)
          .then(res => {
            this.noteRecommendList = res.data.list
            this.total = res.data.total
          })
    },
    changePageNum(val) { // 修改页码
      this.page = val
      this.getDiscoverList()
    },
    changePageSize(val) { // 修改页数
      this.page = 1
      this.limit = val
      this.getDiscoverList()
    },
    selectedKeys(e) {
      this.page = 1
      this.activeGxKey = e
      this.getDiscoverList()
    }
  }
}
</script>

<style scoped lang="scss">
.title-tg {
  padding-left: 10px;
  padding-right: 10px;
  height: 22px;
  font-size: 14px;
  border-radius: 100px;
  background: #FF6600;
  text-align: center;
  color: #fff;
  margin-right: 10px;
}

.item-item-btns {
  width: 84px;
  height: 26px;
  border-radius: 50px;
  opacity: 1;
  background: #2b85e4;

  font-size: 12px;
  font-weight: normal;
  line-height: 25px;
  letter-spacing: 0px;
  color: #FFFFFF;
}

.item-item-btn {
  width: 84px;
  height: 26px;
  border-radius: 50px;
  opacity: 1;
  background: rgba(68, 68, 68, 0.6);

  font-size: 12px;
  font-weight: normal;
  line-height: 25px;
  letter-spacing: 0px;
  color: #FFFFFF;
}

.list-title {
  margin-top: 6px;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}

.list-time {
  margin-top: 10px;
  font-size: 12px;
  font-weight: 350;
  color: #A7A7A7;
}

.list-content {
  margin-top: 6px;
  font-size: 14px;
  color: #999999;
  height: 45px;
}

.page-size {
  text-align: right;
}

.list-g-x {
  padding: 15px 0 15px 10px;
}

.container {
  margin: 0 auto;
  padding: 3px 0;
  border-radius: 12px;
  min-height: 1200px;

  .item-font {
    font-size: 16px;
    font-weight: bold;
    line-height: normal;
  }
}

.ant-menu-light {
  background: inherit !important;
}

</style>
