<template>
  <div class="wrapper">
    <a-card class="cart-users" :bodyStyle="{padding: '20px 30px'}" :loading="loading">
      <div class="d-flex">
        <div class="text-df text-bold me-auto">我的收藏</div>
        <div class="d-flex" v-if="collectProductList && collectProductList.length > 0">
          <div class="ml-3 cu-center cursor-pointer" v-if="selected">
            <a-checkbox v-model:checked="checked" @change="allSelectItems">全选</a-checkbox>
          </div>

          <div class="ml-2 card-del-check-S cu-center cursor-pointer" v-if="selected"
               @click="delCollectionAll">
            删除
          </div>

          <div class="multiple cursor-pointer" :class="selected ? 'multiple-selected' : ''" @click="allSelect">
            {{ selected ? '退出管理' : '批量管理' }}
          </div>
        </div>
      </div>
      <div class="mt-4">
        <empty v-if="!collectProductList || collectProductList.length === 0"/>
        <a-row v-else :gutter="[16,24]">
          <a-col :span="6" v-for="(item,index) in collectProductList" :key="index">
            <a-card :bodyStyle="{padding: '3px'}" :hoverable="true"
                    @mouseenter="showFirstIndex = item.productId"
                    @mouseleave="showFirstIndex = -1">
              <div class="card-image">
                <a-image :width="206" :height="206" :preview="false" :src="item.image"
                         @click="viewProduct(item.productId)"/>
                <div class="card-del cu-center cursor-pointer" v-if="showFirstIndex === item.productId && !selected"
                     @click="delCollection(item.productId)">
                  <Icon class="text-bold" type="ios-trash-outline" :size="14"/>
                </div>
                <div class="card-del-check cu-center cursor-pointer" v-if="selected">
                  <a-checkbox v-model:checked="item.right" v-model:value="item.productId"
                              @change="itemChange($event,item.productId)"/>
                </div>
              </div>
              <div class="padding-5 product-name line2" @click="viewProduct(item.productId)"> {{ item.name }}</div>
              <div class="padding-5 text-red text-bold mb-3" @click="viewProduct(item.productId)"> {{
                  item.price
                }} USDT
              </div>
            </a-card>
          </a-col>
        </a-row>
      </div>
    </a-card>
    <!-- 分页 -->
    <div class="page-size mt-3" v-if="collectProductList && collectProductList.length > 0">
      <a-pagination :page-size="limit" :total="total" show-less-items @change="changePageNum"
                    :show-size-changer="false"
                    @page-size-change="changePageSize"/>
    </div>
  </div>
</template>

<script>
import { collectCancel, getCollectUserList } from '@/api/product.js'

export default {
  name: 'MyCoupon',
  data() {
    return {
      selected: false,
      showFirstIndex: 0,
      footerswitch: true,
      hostProduct: [],
      loading: false,
      collectProductList: [],
      limit: 12,
      page: 1,
      hotScroll: false,
      isAllSelect: false, //全选
      selectValue: [], //选中的数据
      delBtnWidth: 80, //左滑默认宽度
      total: 0,
      checked: false,
    }
  },
  created() {
    this.get_user_collect_product()
  },
  methods: {
    viewProduct(goodsId) {
      this.$router.push({
        path: '/Product',
        query: {
          id: goodsId,
        }
      })
    },
    itemChange(e, itemId) {
      if (e.target.checked) {
        if (this.selectValue && this.selectValue.length > 0) {
          this.selectValue.push(itemId)
        } else {
          this.selectValue = []
          this.selectValue.push(itemId)
        }
      } else {
        if (this.selectValue && this.selectValue.length > 0) {
          let array = []
          this.selectValue.forEach((item) => {
            if (item !== itemId) {
              array.push(item)
            }
          })
          this.selectValue = array
        }
      }
    },
    allSelectItems(e) {
      this.selectValue = []
      if (this.selected) {
        if (e.target.checked) {
          this.collectProductList.forEach(item => {
            item.right = true
            this.selectValue.push(item.productId)
          })
        } else {
          this.collectProductList.forEach(item => {
            item.right = false
          })
        }
      }

    },
    allSelect() {
      this.selected = !this.selected
    },
    /**
     * 取消收藏
     */
    delCollection: function (id) {
      this.selectValue = id
      this.del({
        ids: this.selectValue.toString()
      })
    },
    delCollectionAll: function () {
      if (!this.selectValue || this.selectValue.length === 0) {
        return this.$Message.error('请选择要取消的商品')
      }
      this.del({
        ids: this.selectValue.toString()
      })
    },
    del: function (data) {
      collectCancel(data)
          .then(res => {
            this.selectValue = []
            this.collectProductList = []
            this.selected = false
            this.page = 1
            this.get_user_collect_product()
          })
    },
    changePageNum(val) { // 修改页码
      this.page = val
      this.get_user_collect_product()
    },
    changePageSize(val) { // 修改页数
      this.page = 1
      this.limit = val
      this.get_user_collect_product()
    },
    get_user_collect_product() {
      let that = this
      that.loading = true
      getCollectUserList({
        page: that.page,
        limit: that.limit
      })
          .then(res => {
            res.data.list.map(item => {
              item.right = false
            })
            that.total = res.data.total
            that.collectProductList = res.data.list
            that.loading = false
          })
    },
  }
}
</script>

<style scoped lang="scss">
.card-del-check-S {
  padding-left: 10px;
  padding-right: 10px;
  height: 24px;
  border-radius: 5px;
  box-sizing: border-box;
  border: 1px solid #e8e8e8;

  margin-left: 10px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 13px;
  font-weight: 350;
  line-height: normal;
  letter-spacing: 0em;
  color: grey;
}

.multiple-selected {
  padding-left: 15px !important;
  padding-right: 15px !important;
  border-radius: 5px !important;
  background-color: #269FFA;
  color: #FFFFFF !important;
}

.multiple {
  padding-left: 10px;
  padding-right: 10px;
  height: 24px;
  border-radius: 5px;
  box-sizing: border-box;
  border: 1px solid #e8e8e8;

  margin-left: 10px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 13px;
  font-weight: 350;
  line-height: normal;
  letter-spacing: 0em;
  color: grey;
}

.product-name {
  font-size: 14px;
  font-weight: normal;
  line-height: 26px;
  letter-spacing: 0em;
  color: #3D3D3D;

  height: 56px;
}

.card-image {
  position: relative;

  .card-del-check {
    position: absolute;
    top: 5px;
    left: 15px;
    width: 28px;
    height: 28px;
    border-radius: 5px;
    opacity: 0.8;

    color: #FFFFFF;

  }

  .card-del {
    position: absolute;
    top: 5px;
    right: 15px;
    width: 28px;
    height: 28px;
    border-radius: 5px;
    opacity: 0.8;
    background: #5D5D5D;

    color: #FFFFFF;
  }
}

.wrapper {
  width: 100%;

  .cart-users {
    width: 100%;
    border-radius: 12px;
    opacity: 1;
  }
}
</style>
