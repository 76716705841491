<template>
  <div class="wrapper">
    <a-card class="cart-users" :bodyStyle="{padding: '20px 30px'}" :loading="loading">
      <div class="text-df text-bold me-auto">关注店铺</div>
      <div class="mt-4">
        <empty v-if="!storeList || storeList.length === 0"/>
        <a-card class="mb-5" v-for="(item,index) in storeList" :key="index" :body-style="{padding: '18px 15px'}">
          <template #title>
            <div class="d-flex mt-1 mb-2 pa-1">
              <div class="d-flex justify-center align-center">
                <a-avatar shape="square" :size="45" :src="item.merAvatar"/>
              </div>
              <div class="d-flex flex-column">
                <div class="line1 ml-4 mt-1">{{ item.merName }}</div>
                <div class="text-sm ml-4 text-gray">{{ item.createTimeStr }}</div>
              </div>
            </div>
          </template>

          <template #extra>
            <div class="d-flex">
              <div class="cancel-btn cu-center text-blue cursor-pointer"
                   @click="()=>{$router.push({path:'/MerHome' , query:{
                    merId: item.merId
                  }})}">
                进入商铺
              </div>
              <div class="ml-3 cancel-btn cu-center cursor-pointer" @click="followToggle(item)">
                <Icon class="text-bold" type="ios-trash-outline" :size="14"/>
                取消关注
              </div>
            </div>
          </template>

          <arco-scrollbar type="embed" style="overflow: auto;height: 232px;" v-if="item.proList.length > 0">
            <div class="d-flex flex-row cursor-pointer">
              <div class="imgs ml-3" v-for="(items, index) in item.proList" :key="index"
                   @click="() => {$router.push({path:'/Product', query:{ id: items.id}})}">
                <a-image :preview="false" :src="items.image" :width="139" :height="139"
                         style="border-radius: 10px;"
                         :fallback="require('@/assets/index/logo.png')"/>
                <div class="product-name line2" style="width: 139px;">{{ items.name }}</div>
                <div class="text-red text-bold mb-3">{{ items.price }} USDT</div>
              </div>
            </div>
          </arco-scrollbar>
        </a-card>
      </div>
    </a-card>
    <!-- 分页 -->
    <div class="page-size mt-3" v-if="storeList && storeList.length > 0">
      <a-pagination :page-size="limit" :total="total" show-less-items @change="changePageNum"
                    :show-size-changer="false"
                    @page-size-change="changePageSize"/>
    </div>
  </div>
</template>

<script>
import { getMerCollectCancelApi, getMerCollectListApi } from '@/api/merchant.js'

export default {
  name: 'MyCoupon',
  data() {
    return {
      page: 1,
      limit: 12,
      total: 0,
      loading: false,
      storeList: [],
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      this.loading = true
      getMerCollectListApi({
        page: this.page,
        limit: this.limit
      })
          .then(res => {
            this.storeList = res.data.list
            this.total = res.data.total
            this.loading = false
          })
    },
    // 设置是否关注
    followToggle(info) {
      getMerCollectCancelApi(info.merId)
          .then(res => {
            this.page = 1
            this.getList()
          })
    },
    changePageNum(val) { // 修改页码
      this.page = val
      this.getList()
    },
    changePageSize(val) { // 修改页数
      this.page = 1
      this.limit = val
      this.getList()
    },
  }
}
</script>

<style scoped lang="scss">
:deep(.ant-card .ant-card-head ) {
  border-bottom: 0;
}

.imgs {
  position: relative;
  margin-right: 15px;

  .usdt-bottom {
    width: 100%;
    position: absolute;
    bottom: 0;
    height: 24px;
    background: rgba(0, 0, 0, 0.5);
    text-align: center;

    font-size: 12px;
    font-weight: 900;
    line-height: 26px;

    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

.mer-card-content {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
}

.left {
  height: 100%;
  border-right: 1px solid #E8E8E8;
}

.cancel-btn {
  width: 93px;
  height: 32px;
  border-radius: 50px;
  opacity: 1;
  box-sizing: border-box;
  border: 1px solid #DBDBDB;

  font-size: 12px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0em;

  color: #999999;
}

.card-del-check-S {
  width: 72px;
  height: 24px;
  border-radius: 50px;
  box-sizing: border-box;
  border: 1px solid #e8e8e8;

  margin-left: 10px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 13px;
  font-weight: 350;
  line-height: normal;
  letter-spacing: 0em;
  color: grey;
}

.multiple-selected {
  width: 72px !important;
  background-color: #269FFA;
  color: #FFFFFF !important;
}

.multiple {
  width: 50px;
  height: 24px;
  border-radius: 50px;
  box-sizing: border-box;
  border: 1px solid #e8e8e8;

  margin-left: 10px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 13px;
  font-weight: 350;
  line-height: normal;
  letter-spacing: 0em;
  color: grey;
}

.product-name {
  font-size: 14px;
  font-weight: normal;
  line-height: 26px;
  letter-spacing: 0em;
  color: #3D3D3D;

  height: 56px;
}

.card-image {
  position: relative;

  .card-del-check {
    position: absolute;
    top: 5px;
    right: 15px;
    width: 28px;
    height: 28px;
    border-radius: 5px;
    opacity: 0.8;

    color: #FFFFFF;

  }

  .card-del {
    position: absolute;
    top: 5px;
    right: 15px;
    width: 28px;
    height: 28px;
    border-radius: 5px;
    opacity: 0.8;
    background: #5D5D5D;

    color: #FFFFFF;
  }
}

.wrapper {
  width: 100%;

  .cart-users {
    width: 100%;
    border-radius: 12px;
    opacity: 1;
  }
}
</style>
