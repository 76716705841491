<template>
  <div class="wrapper">
    <a-card class="cart-users" :bodyStyle="{padding: '20px 30px'}">
      <template #title>
        账户设置
      </template>
      <a-card class="cart-users" :bordered="true" :bodyStyle="{padding: '10px 10px'}">
        <a-from>
          <a-form-item>
            安全级别
          </a-form-item>
          <a-form-item>
            <div class="passwordstrength">
              <div class="level1"
                   :style="{'background-color':(score>=10&&score<25)?'red':((score>=25&&score<35)?'#FF9900':(score>=35?'#33CC00':'#BBBBBB'))}">
              </div>
              <div class="level2"
                   :style="{'background-color':((score>=25&&score<35)?'#FF9900':(score>=35?'#33CC00':'#BBBBBB'))}">
              </div>
              <div class="level3" :style="{'background-color':score>=35?'#33CC00':'#BBBBBB'}"></div>
            </div>
            <div v-if="score < 25" class="text-red margin-top-xs">请至少开启2项验证</div>
          </a-form-item>
        </a-from>
      </a-card>
    </a-card>

    <a-card class="cart-users-list" :bodyStyle="{padding: '20px 30px'}">
      <div class="safe-cell" v-for="(item, index) in list" :key="index">
        <a-row :gutter="[16, 24]">
          <a-col :span="2" class="safe-col">
            <a-avatar :src="item.icon" :size="33"></a-avatar>
          </a-col>
          <a-col :span="3" class="safe-col">
            <div>{{ item.name }}</div>
          </a-col>
          <a-col :span="11" class="safe-col">
            <div class="text-gray">{{ item.info }}</div>
          </a-col>
          <a-col :span="4" class="safe-col4">
            <div class="text-gray ce-center"
                 style="color: #66A865"
                 v-if="item.buildFlag && (item.key === 'phone' || item.key === 'email' || item.key === 'google')">
              <Icon type="ios-checkmark-circle" :size="20"/>
              已绑定
            </div>
            <div class="ce-center"
                 style="color: #FF8787"
                 v-if="!item.buildFlag && (item.key === 'phone' || item.key === 'email' || item.key === 'google')">
              <Icon type="ios-alert" :size="20"/>
              未绑定
            </div>
            <div class="text-gray ce-center"
                 style="color: #66A865"
                 v-if="(!item.buildFlag && item.key === 'paypwd') || (item.buildFlag && item.key === 'loginpwd')">
              <Icon type="ios-checkmark-circle" :size="20"/>
              已设置
            </div>
            <div class="ce-center"
                 style="color: #FF8787"
                 v-if="(item.buildFlag && item.key === 'paypwd') || (!item.buildFlag && item.key === 'loginpwd')">
              <Icon type="ios-alert" :size="20"/>
              未设置
            </div>
          </a-col>
          <a-col :span="4" class="safe-col4">
            <div class="text-gray ce-center setting-info-wei"
                 v-if="!item.buildFlag && (item.key === 'phone' || item.key === 'email' || item.key === 'google')"
                 @click="showModelClick(item)">
              去绑定
            </div>
            <div class="text-gray ce-center setting-info"
                 v-if="item.buildFlag && (item.key === 'phone' || item.key === 'email' || item.key === 'google')"
                 @click="showModelClick(item)">
              去换绑
            </div>
            <div class="text-gray ce-center setting-info-wei"
                 v-if="(!item.buildFlag && item.key === 'paypwd') || (item.buildFlag && item.key === 'loginpwd')"
                 @click="showModelClick(item)">
              修改
            </div>
            <div class="text-gray ce-center setting-info"
                 v-if="(item.buildFlag && item.key === 'paypwd') || (!item.buildFlag && item.key === 'loginpwd')"
                 @click="showModelClick(item)">
              设置
            </div>
          </a-col>
        </a-row>
      </div>
    </a-card>

    <a-modal class="modal-view"
             v-model:open="showModel"
             z-index="10"
             :maskClosable="false">
      <template #title>
        {{ title }}
      </template>
      <div class="padding">
        <a-form>
          <a-form-item style="margin-bottom: 20px">
            <a-input v-if="this.modalIndex === 1" class="input-cell" :placeholder="$t('pwd.pwd_017')"
                     @input="$InputEvent.OnlyNumericLetters($event)"
                     v-in-number
                     v-model:value="phone">
              <template #prefix>
                <Vue3CountryIntl
                    schema="popover"
                    :selectedText="$t('pwd.pwd_018')"
                    :useChinese="true"
                    :searchInputPlaceholder="$t('common.com_002')"
                    v-model="countryCode"
                    v-model:visible="countryView"
                    @change="changeCountry">
                  <Vue3CountryFlag :value="iso2" @click="countryView = !countryView">
                    <template v-slot="{country}">
                      <span style="margin-left: 3px;">{{ '+' + country.dialCode }}</span>
                      <Icon type="md-arrow-dropdown"/>
                    </template>
                  </Vue3CountryFlag>
                </Vue3CountryIntl>
                <a-divider type="vertical" style="margin-left: -6px;"/>
              </template>
              <template #suffix>
                <a-button style="color: #2b85e4" type="link" @click="sendBindingSendPhone"> {{ text }}</a-button>
              </template>
            </a-input>
            <!-- 邮箱-->
            <!--  自动完成 -->
            <a-auto-complete v-if="this.modalIndex === 2" class="mt-3" v-model:value="email" :options="options" @search="handleSearch">
              <template #default>
                <a-input v-if="this.modalIndex === 2" class="input-cell" :placeholder="'请输入邮箱'"
                         @input="$InputEvent.OnlyNumericLetters($event)"
                         v-model:value="email">
                  <template #suffix>
                    <a-spin :spinning="loadingEmailSend">
                      <a-button :class="!disabled ? 'text-blue' : 'text-gray'" type="link"
                                @click="sendBindingSendEmail">
                        {{ text }}
                      </a-button>
                    </a-spin>
                  </template>
                </a-input>
              </template>
            </a-auto-complete>
            <!--  自动完成 -->
          </a-form-item>
          <a-form-item>
            <a-input class="input-cell" :placeholder="$t('pwd.pwd_022')" v-model:value="captcha"
                     @input="$InputEvent.OnlyNumericLetters($event)"
                     :maxlength="6"></a-input>
          </a-form-item>
        </a-form>
      </div>
      <template #footer>
        <div class="cu-center">
          <div class="pay-btn cursor-pointer cu-center" @click="submit">确认</div>
        </div>
      </template>
    </a-modal>

    <a-modal class="modal-view"
             v-model:open="showGoogleModel"
             z-index="10"
             :width="titleFlag === 1 ? 420 :820"
             :maskClosable="false">
      <template #title>
        {{ title }}
      </template>
      <div class="padding-xs bgtop">
        <a-timeline v-if="titleFlag === 0">
          <a-timeline-item>
            ① 打开 Google Authenticator ,点击右下角+
            <div>
              <a-image :preview="false" :src="require('@/assets/safe/Google-1.png')" :height="210"
                       :width="320"></a-image>
            </div>
          </a-timeline-item>
          <a-timeline-item>
            <div class="content shadow-blur">
              ② 选择输入设置密钥或扫码
              <div>
                <a-image :preview="false" :src="require('@/assets/safe/Google-2.png')" :height="210"
                         :width="320"></a-image>
              </div>
            </div>
          </a-timeline-item>
          <a-timeline-item>
            ③ 扫码下方二维码或输入密钥与账户名称
            <div>密钥: {{ googlePrivateKey }}
              <text class="text-blue cursor-pointer" @click="$Copy({text: googlePrivateKey})">{{ ' ' }} 复制</text>
            </div>
            <div class="canvas">
              <a-qrcode :value="qrCodeText"/>
            </div>
          </a-timeline-item>
          <a-timeline-item>
            <div class="text-white flex justify-center align-center margin-top-sm cursor-pointer"
                 style="height: 36px; width: 120px;border-radius: 2px; background-color: #269FFA;" @click="toValid()">
              去验证
            </div>
          </a-timeline-item>
        </a-timeline>
        <div v-if="titleFlag === 1">
          <div class="cu-center flex-column">
            <div>
              <a-qrcode :value="qrCodeText"/>
            </div>
            <div class="mt-3">
              密钥: {{ googlePrivateKey }}
              <Icon :size="18" class="cursor-pointer" type="ios-copy-outline" @click="$Copy({text: googlePrivateKey})"/>
            </div>
          </div>
          <div class="cu-center" style="width: 100%">
            <div class="text-white flex justify-center align-center margin-top-sm cursor-pointer"
                 style="height: 36px; width: 120px;border-radius: 12px; background-color: #269FFA;" @click="toValid()">
              去验证
            </div>
          </div>
        </div>
      </div>
      <template #footer>
      </template>
    </a-modal>

    <s-m-s-phone-and-email :visible="showSms" :title="tips" :modalIndex="modalIndex"
                           @complete="onComplete"></s-m-s-phone-and-email>

    <a-modal class="modal-view"
             v-model:open="showPwdModel"
             z-index="10"
             :width="480"
             :maskClosable="false">
      <template #title>
        {{ title }}
      </template>
      <div class="padding-xs">
        <a-tabs v-model:activeKey="activeKey" @change="changeOrderStatus">
          <a-tab-pane :key="0" v-if="!setting">
            <template #tab>原密码</template>
          </a-tab-pane>
          <a-tab-pane :key="1" v-if="userTagInfo.email">
            <template #tab>{{ $t('pwd.pwd_003') }}</template>
          </a-tab-pane>
          <a-tab-pane :key="2" v-if="userTagInfo.phone">
            <template #tab>手机</template>
          </a-tab-pane>
        </a-tabs>
        <div v-if="activeKey === 1 || activeKey === 2">
          {{ tips }}
        </div>
        <div>
          <a-form :model="formRegist" @finish="onFinish">
            <a-form-item name="captcha" :rules="[{ required: true, message: $t('signUp.s_00044') }]"
                         style="margin-bottom: 10px;margin-top: 10px" v-if="activeKey === 1 || activeKey === 2">
              <a-input class="input-cell" v-model:value="formRegist.captcha" :placeholder="$t('pwd.pwd_022')"
                       @input="$InputEvent.OnlyNumericLetters($event)"
                       :maxlength="6">
                <template #suffix>
                  <a-spin :spinning="sendCodeLoading">
                    <div class="cursor-pointer" @click="codeSend" :class="!disabled ? 'text-blue' : 'text-gray'">
                      {{ text }}
                    </div>
                  </a-spin>
                </template>
              </a-input>
            </a-form-item>
            <a-form-item name="oldPwd" :rules="[{ required: true, message: '请输入原密码' }]"
                         style="margin-bottom: 10px;margin-top: 10px" v-if="activeKey === 0">
              <a-input-password :maxlength="modalIndex === 4 ? 6 : 10000" v-model:value="formRegist.oldPwd"
                                @input="$InputEvent.OnlyNumericLetters($event)"
                                v-no-chinese
                                class="input-cell" placeholder="请输入原密码"></a-input-password>
            </a-form-item>
            <a-form-item name="password"
                         :rules="[{ required: true, message: $t('pwd.pwd_013') }, { validator: validateConfirmPassword }]"
                         style="margin-bottom: 10px;margin-top: 10px">
              <a-input-password :maxlength="modalIndex === 4 ? 6 : 10000" v-model:value="formRegist.password"
                                @input="$InputEvent.OnlyNumericLetters($event)"
                                class="input-cell"
                                v-no-chinese
                                :placeholder="$t('pwd.pwd_013')"></a-input-password>
            </a-form-item>
            <a-form-item name="password1"
                         :rules="[{ required: true, message: '请输入确认密码' }, { validator: validateConfirmPassword1 }]"
                         style="margin-bottom: 10px;margin-top: 10px">
              <a-input-password :maxlength="modalIndex === 4 ? 6 : 10000" v-model:value="formRegist.password1"
                                @input="$InputEvent.OnlyNumericLetters($event)"
                                class="input-cell"
                                v-no-chinese
                                placeholder="请输入确认密码"></a-input-password>
            </a-form-item>
            <a-form-item :label="$t('pwd.pwd_015')" v-if="modalIndex === 5">
              <pwdCom :password="formRegist.password" @change="pwdChange"/>
            </a-form-item>
            <a-form-item>
              <div class="text-right mt-3">
                <a-button html-type="submit" type="primary">确认</a-button>
              </div>
            </a-form-item>
          </a-form>
        </div>
      </div>
      <template #footer/>
    </a-modal>
  </div>
</template>

<script>
import {
  bindingEmail,
  bindingPhone,
  bindingSendEmail,
  bindingSendPhone,
  checkCodeByPaypwd,
  getUserInfo,
  googleBuild,
  googleCheck,
  googleQrcodeText,
  googleUpdateText,
  googleUpdtaeBuild,
  sendCodeByPaypwd,
  updateUserPwd,
  userCenterInfo,
  userPwdInfo
} from '@/api/user.js'
import SMSPhoneAndEmail from '@/components/sms/index.vue'
import sendVerifyCode from '@/utils/SendVerifyCode'
import { Debounce } from '@/utils/validate'

import pwdCom from '@/components/pwd.vue'
import { msgCusTempSave } from '@/api/message'
import FingerprintJS from '@fingerprintjs/fingerprintjs'

export default {
  name: 'AccountSafe',
  components: {
    SMSPhoneAndEmail,
    pwdCom
  },
  data() {
    return {
      titleFlag: 0,
      score: 10,
      userInfo: {},
      showModel: false,
      showGoogleModel: false,
      list: [
        {
          key: 'phone',
          icon: require('@/assets/safe/phone.png'),
          name: '手机',
          info: '绑定手机后，您即可享受丰富的手机服务，如手机找回密码等。',
          buildFlag: false
        }, {
          key: 'email',
          icon: require('@/assets/safe/email.png'),
          name: this.$t('pwd.pwd_003'),
          info: '绑定邮箱后，您即可享受丰富的邮箱服务，如邮箱找回密码等。',
          buildFlag: false
        }, {
          key: 'google',
          icon: require('@/assets/safe/google.png'),
          name: '谷歌身份验证器',
          info: '绑定后每30s生成一个动态验证码，验证码可用于登录、修改安全设置等操作的安全验证。',
          buildFlag: false
        }, {
          key: 'paypwd',
          icon: require('@/assets/safe/paypwd.png'),
          name: '资金密码',
          info: '支付所购买商品或服务时使用,保护资金安全！',
          buildFlag: false
        }, {
          key: 'loginpwd',
          icon: require('@/assets/safe/loginpwd.png'),
          name: '登录密码',
          info: '定期更换密码有助于账号安全',
          buildFlag: false
        }],
      title: '',
      modalIndex: 0,
      showSms: false,
      tips: '',
      countryView: false,
      userTagInfo: '',
      verification: '',
      countryCode: '1',
      iso2: 'us',
      phone: '',
      captcha: '',
      email: '',
      showPwdModel: false,
      // 1 绑定 2 更换
      qrCodeSize: 150,
      key: '1',
      qrCodeText: '',
      googlePrivateKey: '',
      modalName: false,
      codeAccount: '',
      sendCodeFlag: 1,
      showPassword: true,
      showUpdate: false,
      googleUpdate: false,
      setting: '',
      activeKey: 1,
      formRegist: {
        oldPwd: '',
        password: '',
        password1: '',
        captcha: '',
      },
      pwdLevel: 0,
      timeTask: null,
      timeTaskFlag: null,
      sendCodeLoading: false,
      selectItem: null,
      loadingEmailSend: false,
      options: [],
      emailSuffixOptions: [
        'gmail.com',
        'yahoo.com',
        'hotmail.com',
        'outlook.com',
        'icloud.com',
      ],
    }
  },
  mixins: [sendVerifyCode],
  mounted() {
    this.getUserCenterInfo()
    this.getUserTagInfo()
    if (this.key === '1') {
      this.getGoogleQrcodeText()
    }
  },
  methods: {
    handleSearch(val) {
      let res = []
      if (!val || val.indexOf('@') >= 0) {
        res = []
      } else {
        res = this.emailSuffixOptions.map(domain => ({ value: `${val}@${domain}` }))
      }
      this.options = res
    },
    validateConfirmPassword(rule, value, callback) {
      if (this.modalIndex === 4) {
        if (!/^\d{6}$/.test(this.formRegist.password)) {
          callback(new Error('密码输入不正确,资金密码为6位数数字'))
        } else {
          callback() // 验证通过
        }
      }
      if (this.modalIndex === 5) {
        if (!/^(?=.*[A-Za-z])(?=.*\d).{8,}$/i.test(this.formRegist.password)) {
          callback(new Error('密码必须包含至少1个字母和至少1个数字，且长度不低于8位。'))
        } else {
          callback() // 验证通过
        }
      }
    },
    validateConfirmPassword1(rule, value, callback) {
      if (this.modalIndex === 5) {
        if (value !== this.formRegist.password) {
          callback(new Error('两次输入的密码不一致，请重新输入。'))
        } else {
          callback() // 验证通过
        }
      }
      if (this.modalIndex === 4) {
        if (value !== this.formRegist.password) {
          callback(new Error('两次输入的密码不一致，请重新输入。'))
        } else {
          callback() // 验证通过
        }
      }
    },
    onFinish() {
      let that = this
      if (!that.formRegist.password) return that.$Message.error(this.$t('signUp.s_00033'))
      if (this.modalIndex === 5) {
        if (!/^(?=.*[A-Za-z])(?=.*\d).{8,}$/i.test(that.formRegist.password)) return that.$Message.error(this.$t('signUp.s_00034'))
      }
      if (!that.formRegist.password1) return that.$Message.error(this.$t('signUp.s_00035'))
      if (that.formRegist.password !== that.formRegist.password1) return that.$Message.error(this.$t('signUp.s_00036'))
      if (this.modalIndex === 4) {
        if (!/^\d{6}$/.test(that.formRegist.password)) {
          return that.$Message.error('密码输入不正确,资金密码为6位数数字')
        }
        if (that.formRegist.oldPwd && !/^\d{6}$/.test(that.formRegist.oldPwd)) {
          return that.$Message.error('密码输入不正确,资金密码为6位数数字')
        }
      }
      if (this.formRegist.oldPwd === this.formRegist.password) {
        return that.$Message.error('新旧密码输入不可相同,请重新输入')
      }
      if (this.modalIndex === 4) {
        this.updateUserPws()
      }
      if (this.modalIndex === 5) {
        this.updateUserPwd()
      }
    },
    updateUserPws: Debounce(function () {
      let data = {
        oldPwd: this.formRegist.oldPwd ? this.formRegist.oldPwd : '000000',
        password: this.formRegist.password,
        checkType: this.formRegist.oldPwd ? 3 : this.activeKey === 1 ? 2 : this.activeKey === 2 ? 1 : 0,
        captcha: this.formRegist.captcha ? this.formRegist.captcha : '000000'
      }
      userPwdInfo(data)
          .then((res) => {
            if (res.code === 200) {
              this.showPwdModel = false
              this.getUserCenterInfo()
              this.getUserTagInfo()
            } else {
              this.formRegist.password = ''
              this.formRegist.password1 = ''
              this.$Message.error(res.message)
            }
          })
    }),
    updateUserPwd: Debounce(function () {
      let data = {
        oldPwd: this.formRegist.oldPwd ? this.formRegist.oldPwd : '000000',
        password: this.formRegist.password,
        checkType: this.formRegist.oldPwd ? 3 : this.activeKey === 1 ? 2 : this.activeKey === 2 ? 1 : 0,
        captcha: this.formRegist.captcha ? this.formRegist.captcha : '000000'
      }
      updateUserPwd(data)
          .then((res) => {
            if (res.code === 200) {
              this.showPwdModel = false
              this.outLogin()
            } else {
              this.formRegist.password = ''
              this.formRegist.password1 = ''
              this.$Message.error(res.message)
            }
          })
    }),
    /**
     * 退出登录
     */
    outLogin() {
      this.$store.dispatch('GETUPDATELOGOUT')
          .then(res => {
            setTimeout(() => {
              this.visitorSource()
            }, 400)
          })
    },
    visitorSource() {
      let that = this
      FingerprintJS.load()
          .then(fp => {
            fp.get()
                .then(result => {
                  let visitorId = result.visitorId
                  let data = {
                    uid: that.$route.query.uid,
                    headImg: visitorId
                  }
                  msgCusTempSave(data)
                      .then(res => {
                        that.$Message.success('请重新登录')
                        that.visitorUserId = res.data
                        that.$store.commit('VISITOR_USER_ID', this.visitorUserId)
                        that.$router.push({ path: '/Login' })
                      })
                })
          })
    },
    pwdChange(event) {
      this.pwdLevel = event
    },
    changeOrderStatus(e) {
      if (e === 1) {
        this.tips = this.$t('pwd.pwd_008') + ': ' + this.userTagInfo.email + this.$t('pwd.pwd_009')
      }
      if (e === 2) {
        this.tips = this.$t('pwd.pwd_008') + ': +' + this.userTagInfo.countryCode + '  ' + this.userTagInfo.phone + ', ' + this.$t('pwd.pwd_024')
      }
    },
    toValid() {
      this.showGoogleModel = false
      this.tips = '请输入Google验证码'
      this.showSms = true
    },
    getGoogleQrcodeText() {
      googleQrcodeText()
          .then(res => {
            this.qrCodeText = res.data.qrCodeText
            this.googlePrivateKey = res.data.googlePrivateKey
          })
    },
    sendBindingSendEmail: Debounce(function () {
      let that = this
      if (that.disabled) return
      if (!that.email) return that.$Message.error(this.$t('pwd.pwd_004'))
      if (!/^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/i.test(that.email)) return that.$Message.error('邮箱输入错误')
      this.loadingEmailSend = true
      bindingSendEmail({
        email: that.email,
        captcha: '000000'
      })
          .then(res => {
            this.loadingEmailSend = false
            if (res.code === 200) {
              this.timeTask = that.sendCode()
            } else {
              that.$Message.error(res.message)
            }
          })
    }),
    submitEmail: Debounce(function () {
      let that = this
      if (!that.email) that.$Message.error(this.$t('signUp.s_00041'))
      if (!/^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/i.test(that.email)) return that.$Message.error('邮箱输入错误')
      if (!that.captcha) return that.$Message.error(this.$t('signUp.s_00031'))
      bindingEmail({
        email: that.email,
        captcha: that.captcha
      })
          .then(res => {
            if (res.code === 200) {
              this.showModel = false
              this.getUserCenterInfo()
              this.getUserTagInfo()
              this.$store.dispatch('USERINFO')
                  .then(res => {
                  })
            } else {
              that.$Message.error(res.message)
            }
          })
    }),
    submitPhone: Debounce(function () {
      let that = this
      if (!that.phone) return that.$Message.error(this.$t('signUp.s_00030'))
      if (!that.captcha) return that.$Message.error(this.$t('signUp.s_00031'))
      if (!/^[\w\d]+$/i.test(that.captcha)) return that.$Message.error('正确输入验证码')
      bindingPhone({
        phone: that.phone,
        countryCode: that.countryCode,
        captcha: that.captcha
      })
          .then(res => {
            if (res.code === 200) {
              this.showModel = false
              this.getUserCenterInfo()
              this.getUserTagInfo()
              this.$store.dispatch('USERINFO')
                  .then(res => {
                  })
            } else {
              that.$Message.error(res.message)
            }
          })
    }),
    submit() {
      if (this.modalIndex === 1) {
        this.submitPhone()
      }
      if (this.modalIndex === 2) {
        this.submitEmail()
      }
    },
    changeCountry(event) {
      this.countryCode = event.dialCode
      this.iso2 = event.iso2
    },
    onComplete(valtion) {
      this.showSms = false
      if (!valtion) return
      if (this.modalIndex === 1) {
        this.verification = valtion
        this.buildPhoneCheck()
      }
      if (this.modalIndex === 2) {
        this.verification = valtion
        this.buildEmailCheck()
      }
      if (this.modalIndex === 3) {
        this.verification = valtion
        if (this.googleUpdate) {
          this.updateGoogle()
        } else {
          this.checkGoogle()
        }
      }
    },
    updateGoogle(e) {
      let data = {
        code: this.verification
      }
      googleUpdtaeBuild(data)
          .then(res => {
            if (res.code === 200) {
              this.getUserCenterInfo()
              this.getUserTagInfo()
            } else {
              this.$Message.error('验证失败')
            }
          })
    },
    checkGoogle() {
      let data = {
        code: this.verification
      }
      googleCheck(data)
          .then(res => {
            if (res.code === 200 && res.data) {
              this.buildGoogle()
            } else {
              this.$Message.error('验证失败')
            }
          })
    },
    buildGoogle() {
      if (!this.verification) {
        return
      }
      let data = {
        type: 2,
        code: ''
      }
      googleBuild(data)
          .then(res => {
            if (res.code === 200) {
              this.getUserCenterInfo()
              this.getUserTagInfo()
            } else {
              this.$Message.error(res.message)
            }
          })
    },
    buildEmailCheck() {
      // 检查验证码
      if (!this.verification) {
        return
      }
      let data = {
        sendCodeFlag: 2,
        code: this.verification,
      }
      checkCodeByPaypwd(data)
          .then(res => {
            if (res.code === 200) {
              this.email = ''
              this.captcha = ''
              this.showModel = true
            } else {
              this.$Message.error(res.message)
            }
          })
    },
    codeSend() {
      let that = this
      if (that.disabled) return
      let data = {
        sendCodeFlag: this.activeKey === 1 ? 2 : this.activeKey === 2 ? 1 : 0,
        sendType: this.selectItem.key
      }
      this.sendCodeLoading = true
      sendCodeByPaypwd(data)
          .then(res => {
            this.sendCodeLoading = false
            if (res.code === 200) {
              this.timeTask = that.sendCode()
            } else {
              this.$Message.error(res.message)
            }
          })
    },
    buildPhoneCheck() {
      // 检查验证码
      if (!this.verification) {
        return
      }
      let data = {
        sendCodeFlag: 1,
        code: this.verification,
      }
      checkCodeByPaypwd(data)
          .then(res => {
            if (res.code === 200) {
              this.phone = ''
              this.captcha = ''
              this.showModel = true
            } else {
              this.$Message.error(res.message)
            }
          })
    },
    sendBindingSendPhone() {
      let that = this
      if (that.disabled) return
      if (!that.phone) return that.$Message.error(this.$t('signUp.s_00030'))
      bindingSendPhone({
        phone: that.phone,
        countryCode: that.countryCode,
        captcha: '000000'
      })
          .then(res => {
            if (res.code === 200) {
              this.timeTask = that.sendCode()
            } else {
              this.$Message.error(res.message)
            }
          })
    },
    showModelClick(item) {
      this.selectItem = item
      this.$nextTick(() => {
        if (item.key === 'phone') {
          if (this.timeTaskFlag !== 1 && this.timeTask) {
            clearTimeout(this.timeTask)
            this.timeTask = null
            this.disabled = false
            this.text = '获取验证码'
          }
          this.modalIndex = 1
          this.title = '绑定手机号'
          if (item.buildFlag) {
            this.tips = this.$t('pwd.pwd_008') + ': +' + this.userTagInfo.countryCode + '  ' + this.userTagInfo.phone + ', ' + this.$t('pwd.pwd_024')
            this.showSms = true
          } else {
            this.phone = ''
            this.captcha = ''
            this.showModel = true
          }
          this.timeTaskFlag = 1
        }
        if (item.key === 'email') {
          if (this.timeTaskFlag !== 2 && this.timeTask) {
            clearTimeout(this.timeTask)
            this.timeTask = null
            this.disabled = false
            this.text = '获取验证码'
          }
          this.title = '绑定邮箱'
          this.modalIndex = 2
          if (item.buildFlag) {
            this.tips = this.$t('pwd.pwd_008') + ': ' + this.userTagInfo.email + this.$t('pwd.pwd_009')
            this.showSms = true
          } else {
            this.showModel = true
          }
          this.titleFlag = 2
        }
        if (item.key === 'google') {
          if (this.timeTaskFlag !== 3 && this.timeTask) {
            clearTimeout(this.timeTask)
            this.timeTask = null
            this.disabled = false
            this.text = '获取验证码'
          }
          this.modalIndex = 3
          this.title = '绑定google验证码'
          if (item.buildFlag) {
            this.getGoogleUpdateText()
            this.title = '换绑google验证码'
            this.titleFlag = 1
            this.showGoogleModel = true
          } else {
            this.title = '绑定google验证码'
            this.titleFlag = 0
            this.showGoogleModel = true
          }
          this.timeTaskFlag = 3
        }
        if (item.key === 'paypwd') {
          if (this.timeTaskFlag !== 4 && this.timeTask) {
            clearTimeout(this.timeTask)
            this.timeTask = null
            this.disabled = false
            this.text = '获取验证码'
          }
          this.modalIndex = 4
          this.setting = item.buildFlag
          if (!this.setting) {
            this.activeKey = 0
          } else {
            if (this.userTagInfo.email) {
              this.activeKey = 1
              this.tips = this.$t('pwd.pwd_008') + ': ' + this.userTagInfo.email + this.$t('pwd.pwd_009')
            }
            if (this.userTagInfo.phone) {
              this.activeKey = 2
              this.tips = this.$t('pwd.pwd_008') + ': +' + this.userTagInfo.countryCode + '  ' + this.userTagInfo.phone + ', ' + this.$t('pwd.pwd_024')
            }
          }
          this.title = !item.buildFlag ? '修改资金密码' : '设置资金密码'

          this.formRegist = {
            oldPwd: '',
            password: '',
            password1: '',
            captcha: '',
          }
          this.showPwdModel = true
          this.timeTaskFlag = 4
        }
        if (item.key === 'loginpwd') {
          if (this.timeTaskFlag !== 5 && this.timeTask) {
            clearTimeout(this.timeTask)
            this.timeTask = null
            this.disabled = false
            this.text = '获取验证码'
          }
          this.modalIndex = 5
          this.setting = !item.buildFlag
          if (!this.setting) {
            this.activeKey = 0
          } else {
            this.tips = this.$t('pwd.pwd_008') + ': ' + this.userTagInfo.email + this.$t('pwd.pwd_009')
          }
          this.title = !item.buildFlag ? this.$t('pwd.pwd_011') : '修改登录密码'

          this.formRegist = {
            oldPwd: '',
            password: '',
            password1: '',
            captcha: '',
          }
          this.showPwdModel = true
          this.timeTaskFlag = 5
        }
      })
    },
    getGoogleUpdateText() {
      googleUpdateText()
          .then(res => {
            this.qrCodeText = res.data.qrCodeText
            this.googlePrivateKey = res.data.googlePrivateKey
            this.googleUpdate = true
          })
    },
    getUserTagInfo: function () {
      getUserInfo()
          .then(res => {
            let data = res.data
            this.userTagInfo = data
          })
    },
    getUserCenterInfo() {
      userCenterInfo()
          .then(res => {
            let data = res.data
            this.userInfo = data
            if (this.userInfo.phone) {
              this.list[0].buildFlag = true
            }
            if (this.userInfo.email) {
              this.list[1].buildFlag = true
            }
            if (this.userInfo.isGoogle) {
              this.list[2].buildFlag = true
            }
            this.list[3].buildFlag = !this.userInfo.isPayPassword
            this.list[4].buildFlag = !this.userInfo.isPassword

            if (this.userInfo.email && this.userInfo.isGoogle) {
              this.score = 25
              if (this.userInfo.phone) {
                this.score = 35
              }
            }
            if (this.userInfo.email && this.userInfo.phone) {
              this.score = 25
              if (this.userInfo.isGoogle) {
                this.score = 35
              }
            }
            if (this.userInfo.phone && this.userInfo.isGoogle) {
              this.score = 25
              if (this.userInfo.email) {
                this.score = 35
              }
            }
          })
    },
  }
}
</script>

<style scoped lang="scss">
.pay-btn {
  width: 400px;
  height: 50px;
  border-radius: 6px;
  background: #269FFA;

  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  color: #FFFFFF;
}

.input-cell {
  height: 40px;
  border: 1px solid #E5E5E5;
}

.setting-info {
  width: 86px;
  height: 29px;
  border-radius: 50px;
  opacity: 1;
  background: #269FFA;

  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;

  cursor: pointer;
}

.setting-info-wei {
  width: 86px;
  height: 29px;
  border-radius: 50px;
  opacity: 1;
  border: 1px solid #269FFA;

  display: flex;
  justify-content: center;
  align-items: center;
  color: #269FFA;

  cursor: pointer;
}

.safe-cell {
  height: 94px;
  border-bottom: 1px solid #F0F0F0;

  .safe-col {
    height: 94px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .safe-col3 {
    height: 94px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .safe-col4 {
    height: 94px;

    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
  }
}

.cart-users-list {
  width: 100%;
  margin-top: 20px;
  border-radius: 12px;
  opacity: 1;
}

.wrapper {
  width: 100%;

  .cart-users {
    width: 100%;
    border-radius: 12px;
    opacity: 1;
  }
}

.ant-form-item {
  margin-bottom: 1px !important;
}

.passwordstrength {
  height: 10px;
  width: 315px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;

  .level1 {
    border-radius: 10px;
    height: 7.5px;
    width: 33.3%;
    margin-right: 10px;
  }

  .level2 {
    border-radius: 10px;
    height: 7.5px;
    width: 33.3%;
    margin-right: 10px;
  }

  .level3 {
    border-radius: 10px;
    height: 7.5px;
    width: 33.3%;
  }
}
</style>
