<template>
  <div class="wrapper">
    <a-card v-if="order_pay_info.status === 0" :bordered="false" class="mb-3 box-top" :loading="betweenTime === null">
      <a-row :gutter="24" class="box-card">
        <a-col :span="4" class="pa-6 cu-center-col text-red text-bold">
          <div>
            <a-image :src="require('@/assets/order/wait.png')" :preview="false" width="32px" height="32px"></a-image>
          </div>
          <div class="mt-2">等待付款</div>
        </a-col>
        <a-col :span="5" class="pa-6 cu-center-col">
          <div class="text-bold">订单编号</div>
          <div class="mt-4">{{ order_pay_info.orderNo }}</div>
        </a-col>
        <a-col :span="5" class="pa-6 cu-center-col">
          <div class="text-bold">下单时间</div>
          <div class="mt-4">{{ order_pay_info.createTime }}</div>
        </a-col>
        <a-col :span="5" class="pa-6 cu-center-col">
          <div class="text-bold" v-if="betweenTime">付款截止</div>
          <div class="mt-4 text-red">{{ betweenTime }}</div>
        </a-col>
        <a-col :span="5" class="pa-6 cu-center">
          <a-space>
            <a-button class="rounded" type="primary"
                      @click="() => {$router.push({path:'/Payment', query:{orderNo: order_pay_info.orderNo, payPrice: order_pay_info.payPrice}})}">
              立即付款
            </a-button>
            <a-button class="text-gray"
                      @click="handleCancelOrder">取消订单
            </a-button>
          </a-space>
        </a-col>
      </a-row>
    </a-card>

    <a-card v-if="order_pay_info.status >= 1" :bordered="false" class="mb-3 box-top">
      <a-row :gutter="24" class="box-card">
        <a-col :span="4" class="pa-6 cu-center-col text-red text-bold">
          <div v-if="order_pay_info.status === 1">
            <a-image :src="require('@/assets/order/daifahuo.png')" :preview="false" width="32px"
                     height="32px"></a-image>
          </div>
          <div v-if="order_pay_info.status === 4">
            <a-image :src="require('@/assets/order/daishouhuo.png')" :preview="false" width="32px"
                     height="32px"></a-image>
          </div>
          <div v-if="order_pay_info.status === 6">
            <a-image :src="require('@/assets/order/complate.png')" :preview="false" width="32px"
                     height="32px"></a-image>
          </div>
          <div v-if="order_pay_info.status === 9">
            <a-image :src="require('@/assets/order/close.png')" :preview="false" width="32px"
                     height="32px"></a-image>
          </div>
          <div class="mt-2" v-if="order_pay_info.status === 1">等待发货</div>
          <div class="mt-2" v-if="order_pay_info.status === 4">等待收货</div>
          <div class="mt-2" v-if="order_pay_info.status === 6">交易完成</div>
          <div class="mt-2" v-if="order_pay_info.status === 9">交易关闭</div>
        </a-col>
        <a-col :span="5" class="pa-6 cu-center-col">
          <div class="text-bold">订单编号</div>
          <div class="mt-4">{{ order_pay_info.orderNo }}</div>
        </a-col>
        <a-col :span="5" class="pa-6 cu-center-col">
          <div class="text-bold">下单时间</div>
          <div class="mt-4">{{ order_pay_info.createTime }}</div>
        </a-col>
        <a-col :span="10" class="pa-6 cu-center">
          <a-space>
            <a-button class="rounded" type="primary"
                      v-if="order_pay_info.status === 0"
                      @click="() => {$router.push({path:'/Payment', query:{orderNo: order_pay_info.orderNo, payPrice: order_pay_info.payPrice}})}">
              立即付款
            </a-button>
            <!-- 提醒发货 -->
            <a-button class="rounded" @click="alertOrder(order_pay_info)" type="primary"
                      v-if="order_pay_info.status === 1">
              提醒发货
            </a-button>
            <!-- 修改地址 -->
            <a-button class='rounded text-gray' v-if="order_pay_info.status === 1"
                      @click='showAddress(order_pay_info)'>
              修改地址
            </a-button>
            <a-button class="rounded" @click="received(order_pay_info)" type="primary"
                      v-if="order_pay_info.status === 4">
              确认收货
            </a-button>
            <a-button class="rounded" v-if="order_pay_info.status === 9 || order_pay_info.status === 6"
                      type="primary"
                      danger
                      @click="delOrder()">
              删除订单
            </a-button>
            <a-button class="rounded"
                      v-if="order_pay_info.status === 6 && !order_pay_info.merchantOrderList[0].orderInfoList[0].isReply"
                      @click="evaluate(order_pay_info)" type="primary">
              评价商品
            </a-button>
            <a-button class="rounded text-gray" @click="handleCancelOrder()"
                      v-if="order_pay_info.status === 0"
                      size="small">
              取消订单
            </a-button>
            <a-button class="rounded text-gray"
                      v-if="order_pay_info.status === 9 || order_pay_info.status === 6"
                      @click="goodsDetail(orderInfo.productId)">
              再次购买
            </a-button>
            <!--售后状态：-1:未申请 0:待审核 1:商家拒绝 2：退款中 3:已退款 4:用户退货 5:商家待收货 6:已撤销-->
            <a-button class="rounded text-gray"
                      v-if="order_pay_info.status !== 0 && order_pay_info.status !== 6 && order_pay_info.status !== 9
                      && (order_pay_info.refundStatus === -1 || order_pay_info.refundStatus === 6 || order_pay_info.refundStatus === 1)"
                      @click="applyAfterSale(order_pay_info)">
              申请退款
            </a-button>
            <a-button class="rounded text-gray"
                      v-if="order_pay_info.status !== 0 && order_pay_info.status !== 6 && order_pay_info.status !== 9
                      && (order_pay_info.refundStatus === 0 || order_pay_info.refundStatus === 4)"
                      @click="afterSales(order_pay_info)">
              撤销售后
            </a-button>
          </a-space>
        </a-col>
      </a-row>
    </a-card>

    <div class="mb-3 box" v-if="order_pay_info.status !== 9">
      <a-card :bordered="false" class="box-card">
        <a-steps changeable :current="current" label-placement="vertical">
          <a-step>
            <template #title>提交订单</template>
            <template #subTitle>
              <div class="text-xs">{{ order_pay_info.createTime }}</div>
            </template>
          </a-step>
          <a-step>
            <template #title>付款成功</template>
            <template #subTitle>
              <div class="text-xs" v-if="order_pay_info.payTime">{{ order_pay_info.payTime }}</div>
            </template>
          </a-step>
          <a-step>
            <template #title>商品出库</template>
            <template #subTitle>
              <div class="text-xs" v-if="order_pay_info.sendTime">{{ order_pay_info.sendTime }}</div>
            </template>
          </a-step>
          <a-step>
            <template #title>等待收货</template>
            <template #subTitle>
              <div class="text-xs" v-if="order_pay_info.receivingTime">{{ order_pay_info.receivingTime }}</div>
            </template>
          </a-step>
          <a-step>
            <template #title>交易完成</template>
            <template #subTitle>
              <div class="text-xs" v-if="order_pay_info.receivingTime">{{ order_pay_info.receivingTime }}</div>
            </template>
          </a-step>
        </a-steps>
      </a-card>
    </div>

    <div class="mb-3 box-mini" v-if="order_pay_info.status === 4">
      <a-card :bordered="false" class="box-card">
        <div class="flex justify-between align-center" v-if="InvoiceData.length > 0">
          <div>
            <span class="text-gray">{{ InvoiceList.updateTime }}</span>
            <span class="ml-3">{{ InvoiceList.expressName }}</span>
            <span class="ml-2">{{ InvoiceList.trackingNumber }}</span>
          </div>
          <div>
            <a-button type="primary" size="small" @click="$Copy({text: InvoiceList.trackingNumber})">复制</a-button>
          </div>
        </div>
        <div v-if="InvoiceData.length === 0" class='flex justify-between align-center'>
          商家选择无需物流发货
        </div>
      </a-card>
    </div>

    <div class="mb-3 box-mini" v-if="order_pay_info.status === 6">
      <a-card :bordered="false" class="box-card">
        <div>
          <span class="text-gray">{{ order_pay_info.receivingTime }}</span>
          <span class="ml-3">感谢您在{{ merchantOrder.merName }}购物，欢迎再次光临</span>
        </div>
      </a-card>
    </div>

    <div class="mb-5 box">
      <a-card :bordered="false" :body-style="{padding: '20px'}" :loading="loading">
        <div class="text-lg mb-3">商品信息</div>
        <a-row :gutter="[16,24]" class="bg-gray"
               style="margin-left: 0 !important;margin-right: 0 !important;height: 52px;">
          <a-col :span="8" class="cu-center">
            商品信息
          </a-col>
          <a-col :span="5" class="cu-center">
            单价
          </a-col>
          <a-col :span="2" class="cu-center">
            数量
          </a-col>
          <a-col :span="5" class="cu-center">
            小计
          </a-col>
          <a-col :span="4" class="cu-center">
            实付
          </a-col>
        </a-row>
        <a-row :gutter="[16,24]">
          <a-col :span="8">
            <div class="mt-2 d-flex justify-start">
              <div class="rounded cursor-pointer" @click="goodsDetail(orderInfo.productId)">
                <a-image :preview="false" :src="orderInfo.image" :width="90" :height="90"/>
              </div>
              <div class="flex flex-column cursor-pointer">
                <div class="ml-2 pro-name line3" @click="goodsDetail(orderInfo.productId)">
                  {{ orderInfo.productName }}
                </div>
                <div class="ml-2 mt-2 text-grey text-sm" v-if="orderInfo.sku">{{ orderInfo.sku }}</div>
              </div>
            </div>
          </a-col>
          <a-col :span="5" class="cu-center">
            <div>{{ orderInfo.price || orderInfo.productPrice }} USDT</div>
          </a-col>
          <a-col :span="2" class="cu-center">
            <div>{{ orderInfo.payNum }}</div>
          </a-col>
          <a-col :span="5" class="cu-center">
            <div>{{ order_pay_info.payPrice }} USDT</div>
          </a-col>
          <a-col :span="4" class="cu-center">
            <div class="text-red text-bold">{{ order_pay_info.payPrice }} USDT</div>
          </a-col>
        </a-row>
        <a-divider></a-divider>
        <div class="text-lg mb-3">收货信息</div>
        <div class="order-info">
          <div class="mb-1">收货人: <span class="text-gray">{{ merchantOrder.realName }}</span></div>
          <div class="mb-1">联系方式:
            <span class="text-gray">
              {{ '+' + merchantOrder.userCountryCode + ' ' + merchantOrder.userPhone }}
            </span>
          </div>
          <div class="mb-1">收货地址: <span class="text-gray">{{ merchantOrder.userAddress }}</span></div>
        </div>
        <a-divider></a-divider>
        <div class="text-lg mb-3">订单信息</div>
        <div class="order-info">
          <div class="mb-1 cursor-pointer" @click="$Copy({text: order_pay_info.orderNo})">
            订单编号:
            <span class="text-gray hover-color-blue">
            {{ order_pay_info.orderNo }}
          </span>
            <CopyOutlined class="ml-1 hover-color-blue"/>
          </div>
          <div class="mb-1">下单时间: <span class="text-gray">{{ order_pay_info.createTime }}</span></div>
          <div class="mb-1" v-if="order_pay_info.status === 0">支付方式: <span class="text-gray">在线支付</span></div>
          <div class="mb-1" v-if="order_pay_info.status >= 1">支付方式: <span class="text-gray">余额支付</span></div>
        </div>
        <a-divider></a-divider>
        <div class="mb-6 flex justify-end" style="width: 100%;">
          <div style="width: 210px;">
            <div class="flex justify-between">
              <div>商品件数:</div>
              <div class="text-red text-sm">{{ orderInfo.payNum }} 件</div>
            </div>

            <div class="flex justify-between">
              <div>商品总价:</div>
              <div>{{ order_pay_info.proTotalPrice }} USDT</div>
            </div>

            <div class="flex justify-between">
              <div>应付总额:</div>
              <div class="text-red text-bold">{{ order_pay_info.proTotalPrice }} USDT</div>
            </div>
          </div>
        </div>
        <div class="mb-8">
          <div class="text-right ml-1 mt-5 cursor-pointer cu-center queren-btn" @click="toCustomer"
               style="float:right;">
            联系商家
          </div>
        </div>
      </a-card>
    </div>

    <Evaluate :visible="evaluateView" @change="evaluateChange"/>
    <SendBack :visible="sendBackView" @change="sendBackChange"/>
    <AfterSales :visible="afterSalesView" @change="afterSalesChange"/>
    <ChangeAddress :address-list="addressList" :visible="showPop" @change="changeAdd"/>
  </div>
</template>

<script>
import {
  changeAddress,
  getOrderDetail,
  orderCancel,
  orderComment,
  orderDel,
  orderInvoiceListInfo,
  orderRefundVerify,
  orderTake,
  refundOrderRevokeApi,
  remindMer,
  returningGoodsApi
} from '@/api/order'
import { Modal } from 'ant-design-vue'
import { getAddressList } from '@/api/user'
import AfterSales from '@/components/afterSales/index.vue'
import ChangeAddress from '@/components/changeAddress/index.vue'
import SendBack from '@/components/sendBack/index.vue'
import Evaluate from '@/components/evaluate/index.vue'
import { Debounce } from '@/utils/validate'
import { CopyOutlined } from '@ant-design/icons-vue'

export default {
  name: 'OrderDetails',
  components: {
    CopyOutlined,
    Evaluate,
    SendBack,
    ChangeAddress,
    AfterSales
  },
  data() {
    return {
      orderNo: this.$route.query.orderNo,
      merchantOrder: {},
      loading: false,
      current: 0,
      orderInfo: {},
      order_pay_info: {
        paid: '',
        orderNo: '',
        createTime: '',
        payPrice: '',
      },
      deliveryNum: '',
      num: 0,
      InvoiceList: '',
      InvoiceData: [],
      dbMerId: 0,
      dbOrderNo: '',
      betweenTime: null,
      showPop: false,
      showImg: '',
      selectItem: {},
      showPopAddress: false,
      addressList: [],
      afterSalesView: false,
      sendBackView: false,
      evaluateView: false,
      applyAfterInfo: null,
      evaluateInfo: {},
      refundInfo: {},
      merAddress: {}
    }
  },
  mounted() {
    if (!this.$route.query.orderNo) {
      this.$router.push({ path: '/MyOrder' })
    }
    this.getOrderPayInfo()
  },
  methods: {
    goodsDetail(goodsId) {
      this.$router.push({
        path: '/product',
        query: {
          id: goodsId,
        }
      })
    },
    afterSales(item) {
      this.onRevokeRefund(item)
    },
    onRevokeRefund(item) {
      Modal.confirm({
        title: '确认撤销?',
        onOk: () => {
          refundOrderRevokeApi(item.refundOrderNo ? item.refundOrderNo : item.refundOrder)
              .then(res => {
                this.getOrderPayInfo()
              })
        }
      })
    },
    sendBackChange(e) {
      if (e) {
        this.sendBackForm(e)
      }
      this.sendBackView = false
    },
    afterSalesChange(e) {
      if (e) {
        this.subRefund(e)
      }
      this.afterSalesView = false
    },
    /**
     * 申请退货
     */
    subRefund: Debounce(function (e) {
      let that = this
      orderRefundVerify({
        num: that.applyAfterInfo.totalNum,
        text: e.index || '',
        explain: e.evaluate,
        reasonImage: e.pics && e.pics.length > 0 ? e.pics.join(',') : '',
        orderDetailId: that.applyAfterInfo.merchantOrderList[0].orderInfoList[0].id,
        orderNo: that.applyAfterInfo.orderNo,
        afterSalesType: e.afterSalesType,
        returnGoodsType: e.afterSalesType === 1 ? 0 : 1
      })
          .then(res => {
            if (res.code === 200) {
              this.getOrderPayInfo()
            } else {
              this.$Message.error(res.message)
            }
          })
    }),
    //提交
    sendBackForm: Debounce(function (value) {
      let data
      if (this.order_pay_info.returnGoodsType === 1) {
        data = {
          refundOrderNo: this.order_pay_info.refundOrderNo,
          telephone: value.phone,
          trackingNumber: value.tracking,
          expressName: value.logistics
        }
      } else {
        data = {
          refundOrderNo: this.order_pay_info.refundOrderNo,
          telephone: value.phone
        }
      }
      returningGoodsApi(data)
          .then(res => {
            if (res.code === 200) {
              this.getOrderPayInfo()
            } else {
              this.$Message.error(res.message)
            }
          })
    }),
    evaluateChange(e) {
      if (!e) {
        return this.evaluateView = false
      }
      this.evaluateView = false
      this.formSubmit(e)
    },
    formSubmit(e) {
      let value = {
        comment: e.evaluate,
        star: e.rateProduct,
        pics: e.pics,
        orderDetailId: this.evaluateInfo.id,
        orderNo: this.evaluateInfo.orderNo,
      }
      orderComment(value)
          .then(res => {
            if (res.code === 200) {
              this.$Message.success('感谢您的评价,谢谢!~~')
              this.getOrderPayInfo()
            } else {
              this.$Message.error(res.message)
            }
          })
    },
    alertOrder(order) {
      remindMer(order.orderNo)
          .then(res => {
            if (res.code === 200) {
              this.$Message.success('提醒成功')
            } else {
              this.$Message.warning(res.message)
            }
          })
    },
    changeAdd(addressId) {
      if (!addressId) {
        return this.showPop = false
      }
      changeAddress(this.selectItem.orderNo, addressId)
          .then(res => {
            this.showPop = false
            this.getOrderPayInfo()
            this.$Message.success('修改成功')
          })
          .catch(error => {
            this.showPop = false
            this.$Message.error(error)
          })
    },
    showAddress(order) {
      this.selectItem = order
      getAddressList()
          .then(res => {
            this.addressList = res.data
            this.showPop = true
          })
          .catch(err => {
            this.$Message.error(err)
          })
    },
    timeDifference() {
      let that = this
      setInterval(() => {
        let now = new Date()
        const diffInSeconds = Math.abs(Math.floor((that.order_pay_info.cancelTime - now) /
            1000)) // 计算差值，单位为秒
        const minutes = Math.floor(diffInSeconds / 60) // 转换为分钟
        const seconds = diffInSeconds % 60 // 剩余的秒数
        that.betweenTime = `剩余: ${minutes}分${seconds}秒`
      }, 1000)
    },
    toCustomer() {
      this.dbMerId = this.orderInfo.merId
      this.dbOrderNo = this.orderNo
      let routeUrl = this.$router.resolve({
        path: '/ChatIm',
        query: { //要传的参数 可传多个
          dbMerId: this.dbMerId,
          dbOrderNo: this.dbOrderNo
        }
      })
      window.open(routeUrl.href, '_blank')
    },
    applyAfterSale(order) {
      this.applyAfterInfo = order
      this.afterSalesView = true
    },
    received() {
      Modal.confirm({
        title: '是否确认收货?',
        content: <p>为了保障您的权益，请收到商品确认无误后再确认收货<br/>确认收货后，订单状态将完成，您将无法再申请售后服务，请务必谨慎操作。
        </p>,
        onOk: () => {
          orderTake(this.order_pay_info.orderNo)
              .then(res => {
                this.getOrderPayInfo()
              })
        }
      })
    },
    getOrderInvoiceListInfo() {
      orderInvoiceListInfo(this.order_pay_info.orderNo)
          .then(res => {
            let data = res.data
            this.InvoiceData = data.invoiceList
            this.deliveryNum = data.deliveryNum
            this.num = data.num
            this.InvoiceList = data.invoiceList[0]
          })
    },
    handleCancelOrder() {
      Modal.confirm({
        title: '确认取消',
        onOk: () => {
          orderCancel(this.order_pay_info.orderNo)
              .then(res => {
                this.$router.replace({ name: 'MyOrder' })
              })
        }
      })
    },
    evaluate(item) {
      this.evaluateInfo = item
      this.evaluateView = true
    },
    getOrderPayInfo: function () {
      let that = this
      this.loading = true
      getOrderDetail(that.orderNo)
          .then(res => {
            that.order_pay_info = res.data
            that.merchantOrder = that.order_pay_info.merchantOrderList[0]
            if (that.merchantOrder.deliveryType !== 'fictitious') {
              this.getOrderInvoiceListInfo()
            }
            this.timeDifference()
            this.orderInfo = this.merchantOrder.orderInfoList[0]
            that.current = that.order_pay_info.status
            if (that.current === 4) {
              that.current = 3
            }
            if (that.current === 5) {
              that.current = 4
            }
            if (that.current === 6) {
              that.current = 5
            }
            if (that.current === 9) {
              that.current = -1
            }
            this.loading = false
          })
    },
    delOrder() {
      Modal.confirm({
        title: '确认删除?',
        onOk: () => {
          orderDel(this.orderNo)
              .then(res => {
                this.$router.replace({ name: 'MyOrder' })
              })
        },
      })
    },
  }
}
</script>

<style scoped lang="scss">
.queren-btn-blue {
  width: 93px;
  height: 30px;
  border-radius: 50px;
  opacity: 1;

  box-sizing: border-box;
  border: 1px solid #2b85e4;


  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0em;

  color: #2b85e4;
}

.queren-btn {
  width: 93px;
  height: 30px;
  border-radius: 5px;
  opacity: 1;

  box-sizing: border-box;
  border: 1px solid #C3C3C3;


  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0em;

  color: #3D3D3D;
}

.money {
  font-size: 16px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: 0em;

  font-family: Source Han Sans;
  font-weight: 700;
  font-size: 16px;
}

.order-title {
  font-size: 14px;
  font-weight: normal;
  line-height: 25px;
  letter-spacing: 0em;
}

.order-info {
  font-size: 13px;
  font-weight: normal;
  line-height: 25px;
  letter-spacing: 0em;
  color: #515151;
}

:deep(.ant-steps .ant-steps-item-title::after) {
  top: 20px !important;
}

.wrapper {
  margin-bottom: 40px;
}

.box {
  overflow: hidden;
  width: 100%;
  border-radius: 12px;
  opacity: 1;

  .box-card {
    padding: 26px;
    height: 164px;
  }
}

.box-mini {
  overflow: hidden;
  width: 100%;
  border-radius: 12px;
  opacity: 1;

  .box-card {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.box-top {
  overflow: hidden;
  width: 100%;
  border-radius: 12px;
  opacity: 1;
  background: #FFFFFF;

  .box-card {
    padding: 10px;
    height: 124px;
  }
}
</style>
