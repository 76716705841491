<template>
  <div class="modal-cus">
    <div id="app">
      <div class="sidebar">
        <card @search="searchList"></card>
        <list @change="onChange"
              :dbMerId="dbSelectMerId"
              :keyword="keyword"
              :refreshList="refreshList"
        ></list>
      </div>
      <div class="main">
        <div class="d-flex">
          <div style="width: 700px;">
            <chattitle :selectItem="selectItem" @refresh="refreshMessage"></chattitle>
            <message :selectItem="selectItem"
                     :refreshIndex="refreshIndex"
                     :productInfo="productInfo"
                     :tempMessages="tempMessages"
                     :dbOrderNo="dbOrderNo"
                     :cusTitle="cusTitle"
                     @merInfo="merchant"
                     @refresh="refreshClear"
                     @close="close"></message>

            <usertext :selectItem="selectItem"
                      @customerName="customerName"
                      @refresh="refreshMessage"
                      @tempMessage="tempMessageMit"
                      :productInfo="productInfo"></usertext>
          </div>
          <div class="right-mer-info">
            <div class="d-flex pa-3" v-if="merchantInfo.id >= 0">
              <div style="width: 52px;">
                <a-avatar :src="merchantInfo.avatar" :size="52"/>
              </div>
              <div v-if="merchantInfo.id > 0"
                   style="flex: 1; margin-left: 10px;width: 147px; height: 48px;display: flex;flex-direction: column;justify-content: space-between;">
                <div class="d-flex">
                  <div class="text-bold text-cut cursor-pointer cu-center"
                       :class="merchantInfo.id === 0 ? 'text-xxl' :''" @click="toMer">
                    {{ merchantInfo.name }}
                  </div>
                </div>
                <div class="d-flex" v-if="merchantInfo.id > 0">
                  <div class="join-btn-mer cursor-pointer" @click="toMer">进入店铺</div>
                  <div class="ml-2 cu-center cursor-pointer">
                    <a-avatar :src="require('@/assets/login/dianbao.png')" :size="20" @click="toMerTel"/>
                  </div>
                </div>
              </div>
              <!-- 官方客服 -->
              <div v-else>
                <div class="text-cut ml-2 d-flex justify-start align-center"
                     style="font-size: 23px;height: 52px;font-weight: 400;">
                  Telegram
                </div>
              </div>
            </div>
            <div style="width: 100%;padding: 10px 20px" v-if="merchantInfo.id === 0">
              <a-row :gutter="[16,24]">
                <a-col :span="12">
                  <div class="join-btn-tel cursor-pointer" :class="{ 'clicked': isClicked1 }"
                       @click="toMerClick(merchantInfo.phone, 1)">官方频道
                  </div>
                </a-col>
                <a-col :span="12">
                  <div class="join-btn-tel cursor-pointer" :class="{ 'clicked': isClicked2 }"
                       @click="toMerClick(merchantInfo.phone1, 2)">资原交流群
                  </div>
                </a-col>
                <a-col :span="12">
                  <div class="join-btn-tel cursor-pointer" :class="{ 'clicked': isClicked3 }"
                       @click="toMerClick(merchantInfo.phone2, 3)">供需发布
                  </div>
                </a-col>
                <a-col :span="12">
                  <div class="join-btn-tel cursor-pointer" :class="{ 'clicked': isClicked4 }"
                       @click="toMerClick(merchantInfo.phone3, 4)">曝光资讯
                  </div>
                </a-col>
              </a-row>
            </div>
          </div>
        </div>
      </div>
    </div>
    <admin-web-socket/>
    <web-socket/>
  </div>
</template>

<script>
import card from '@/components/chat/card.vue'
import list from '@/components/chat/list.vue'
import message from '@/components/chat/message.vue'
import usertext from '@/components/chat/usertext.vue'
import toolbar from '@/components/chat/toolbar.vue'
import chattitle from '@/components/chat/chattitle.vue'
import { msgCusInfoSave, msgCusTempSave } from '@/api/message'

import { getProductDetail, } from '@/api/product.js'
import { getOrderDetail } from '@/api/order'

import WebSocket from '@/components/socket/index.vue'
import AdminWebSocket from '@/components/socket/admin-index.vue'

import FingerprintJS from '@fingerprintjs/fingerprintjs'

export default {
  name: 'ChatRoom',
  data() {
    return {
      previewVisible: false,
      dbMerId: 0,
      dbProductId: 0,
      selectItem: {},
      refreshItem: {},
      refreshIndex: -1,
      refreshList: -1,
      keyword: '',
      visitorUserId: '',
      productInfo: {},
      tempMessages: null,
      dbSelectMerId: 0,
      dbOrderNo: '',
      cusTitle: null,
      merchantInfo: {
        id: null,
        name: null,
        phone: null,
      },
      isClicked1: false,
      isClicked2: false,
      isClicked3: false,
      isClicked4: false,
    }
  },
  watch: {
    '$store.getters.chatmsgcount': {
      handler(val) {
        this.refreshIndex = val + 1
      }
    }
  },
  created() {
    this.previewVisible = true
    this.dbMerId = this.$route.query.dbMerId
    this.dbProductId = this.$route.query.dbProductId
    this.dbOrderNo = this.$route.query.dbOrderNo
    this.dbSelectMerId = this.dbMerId
    this.visitorSource()
    setTimeout(() => {
      if (this.dbProductId) {
        this.getGoodsDetails()
      }
      if (this.dbOrderNo) {
        this.getOrderInfo(this.dbOrderNo)
      }
      this.refreshMessage()
    }, 400)
  },
  mounted() {
    document.addEventListener('visibilitychange', this.handleVisibilityChange)
  },
  beforeDestroy() {
    document.removeEventListener('visibilitychange', this.handleVisibilityChange)
  },
  methods: {
    toMerClick(url, index) {
      if (index === 1) {
        this.isClicked1 = true
        setTimeout(() => {
          this.isClicked1 = false
        }, 300)
      }
      if (index === 2) {
        this.isClicked2 = true
        setTimeout(() => {
          this.isClicked2 = false
        }, 300)
      }
      if (index === 3) {
        this.isClicked3 = true
        setTimeout(() => {
          this.isClicked3 = false
        }, 300)
      }
      if (index === 4) {
        this.isClicked4 = true
        setTimeout(() => {
          this.isClicked4 = false
        }, 300)
      }
      window.open(url, '_blank')
    },
    toMer() {
      let routeUrl = this.$router.resolve({
        path: '/MerHome',
        query: { //要传的参数 可传多个
          merId: this.merchantInfo.id
        }
      })
      window.open(routeUrl.href, '_blank')
    },
    toMerTel() {
      window.open(this.merchantInfo.phone, '_blank')
    },
    merchant(e) {
      this.merchantInfo = e
    },
    customerName(e) {
      this.customer = e
    },
    handleVisibilityChange() {
      this.visibilityState = document.visibilityState === 'visible'
      if (this.visibilityState) {
        this.refreshMessage()
      }
    },
    tempMessageMit(msg) {
      if (this.dbSelectMerId === 0 || this.dbSelectMerId === msg.merId) {
        this.tempMessages = msg
      }
    },
    visitorSource() {
      let that = this
      if (!that.$store.getters.uid && that.dbMerId > 0) {
        return
      }
      FingerprintJS.load()
          .then(fp => {
            fp.get()
                .then(result => {
                  let visitorId = result.visitorId
                  let data = {
                    uid: that.$route.query.uid,
                    headImg: visitorId
                  }
                  msgCusTempSave(data)
                      .then(res => {
                        that.visitorUserId = res.data
                        that.saveListMsg(visitorId)
                      })
                })
          })
    },
    saveListMsg(visitorId) {
      let data = {
        merId: this.dbMerId,
        headImg: visitorId
      }
      msgCusInfoSave(data)
          .then(res => {
            this.cusTitle = res.data.adminName
            this.refreshList = this.refreshList + 1
            this.$store.commit('VISITOR_USER_ID', this.visitorUserId)
          })
    },
    refreshClear(e) {
      if (e === 'clear') {
        this.tempMessages = null
      }
      this.refreshMessage()
    },
    getOrderInfo(orderNo) {
      let that = this
      that.tempMessages = null
      getOrderDetail(orderNo)
          .then(res => {
            that.orderInfo = res.data
            if (res.data) {
              setTimeout(() => {
                that.tempMessages = {
                  type: 1,
                  merId: that.orderInfo.merId,
                  linkId: orderNo,
                  orderNo: orderNo,
                  title: '',
                  icon: that.orderInfo.merchantOrderList[0].orderInfoList[0].image,
                  price: that.orderInfo.payPrice,
                  describe: '',
                  isSend: true,
                  orderTime: that.orderInfo.createTime,
                }
              }, 2000)
            }
          })
    },
    getGoodsDetails: function () {
      getProductDetail(this.dbProductId)
          .then(res => {
            this.productInfo = res.data.productInfo
            setTimeout(() => {
              this.tempMessages = {
                type: 1,
                linkId: this.dbProductId,
                orderNo: '',
                title: this.productInfo.name,
                icon: this.productInfo.image,
                price: this.productInfo.price,
                describe: '',
                isSend: true,
                msgTime: '',
              }
            }, 2000)
          })
          .catch(err => {
          })
    },
    searchList(e) {
      this.keyword = e
    },
    visitorSelectSource() {
      let that = this
      if (!that.$store.getters.uid && that.dbSelectMerId > 0) {
        return
      }
      FingerprintJS.load()
          .then(fp => {
            fp.get()
                .then(result => {
                  let visitorId = result.visitorId
                  let data = {
                    uid: that.$route.query.uid,
                    headImg: visitorId
                  }
                  msgCusTempSave(data)
                      .then(res => {
                        that.visitorUserId = res.data
                        that.selectSaveListMsg(visitorId)
                      })
                })
          })
    },
    selectSaveListMsg(visitorId) {
      let data = {
        merId: this.dbSelectMerId,
        headImg: visitorId
      }
      msgCusInfoSave(data)
          .then(res => {
            this.cusTitle = res.data.adminName
          })
    },
    onChange(item) {
      if (!item) {
        return
      }
      this.selectItem = item
      this.dbSelectMerId = this.selectItem.merId
      if (this.dbSelectMerId === 0) {
        this.visitorSelectSource()
      }
      this.tempMessages = null
      this.refreshIndex = this.refreshIndex + 1
    },
    refreshMessage() {
      this.refreshIndex = this.refreshIndex + 1
      this.refreshList = this.refreshList + 1
      console.log(this.refreshIndex)
      this.$store.commit('NO_READ_COUNT', this.refreshIndex)
    },
    close() {
      this.previewVisible = false
      window.close()
    }
  },
  components: {
    toolbar,
    card,
    list,
    message,
    usertext,
    chattitle,
    AdminWebSocket,
    WebSocket
  }
}
</script>

<style lang="scss" scoped>
.join-btn-tel {
  padding-right: 10px;
  padding-left: 10px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 14px;
  height: 30px;
  background: linear-gradient(90deg, #40D1F4 0%, #269FFA 100%);
}

.join-btn-tel:hover {
  background-color: #ff9900;
  transform: scale(0.9);
}

.clicked {
  transform: scale(1.1); /* 点击时放大 */
  background-color: #35495e;
}

.modal-cus {
  position: relative;
  height: 100vh;
  background-color: #E5E5E5;
  background-image: linear-gradient(157deg, #3e07fa 4%, rgba(216, 216, 216, 0) 28%);

  .right-mer-info {
    flex: 1;
    height: 610px;
    margin: 50px 10px 20px 5px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    background-color: #FFFFFF;
  }

  #app {
    position: absolute;
    width: 1200px;
    height: 680px;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    border-radius: 10px;
    background-color: #FFFFFF;

    .sidebar, .main, .toolbar {
      height: 100%;
    }

    .toolbar {
      float: right;
      width: 360px;
    }

    .sidebar {
      float: left;
      width: 240px;
      background-color: #F7F7FA;
      border-right: 1px solid #F7F7FA;
    }

    .main {
      position: relative;
      overflow: hidden;
      background-color: #F0F1F5;
    }

    .right {
      float: right;
      width: 320px;
    }
  }
}
</style>
