<template>
  <div>
    <div class="sign-up">
      <!-- 顶部logo -->
      <div class="top-content">
        <div class="logo-box">
          <div class="left">
            <img
                src="../assets/index/logo.png"
                class="logo-img"
                alt=""
                @click="$router.push({path:'/'})"
            />
            <div class="register">{{ $t('signUp.s_00001') }}</div>
          </div>

          <div class="to-login">
            <a-button type="link" @click="toLogin">{{ $t('signUp.s_00002') }}</a-button>
          </div>
        </div>
      </div>

      <div class="login-center">
        <div class="login-container">
          <div>
            <a-image
                style="border-top-left-radius: 24px !important;border-bottom-left-radius: 24px !important;"
                :width="480"
                :height="473"
                :preview="false"
                :src="require('@/assets/index/register-banner.jpg')"
                :fallback="require('@/assets/index/logo.png')"
            />
          </div>
          <div class="container-right">
            <a-tabs v-model:activeKey="activeKey" @change="changeActive">
              <a-tab-pane key="1" :tab="$t('signUp.s_00003')">
                <div style="padding: 10px 0 0 0" v-if="step === 1">
                  <a-form
                      layout="vertical"
                      :model="formRegist"
                      name="basic"
                      :rules="emailRules"
                      autocomplete="off">
                    <a-form-item name="email"
                                 :hasFeedback="showClearIconEmail && showClearCheckEmail"
                                 :validate-status="emailHelp ? 'error' :'success'"
                                 :help="emailHelp">
                      <!--  自动完成 -->
                      <a-auto-complete v-model:value="formRegist.email" :options="options"
                                       @change="onKeyInputEmail"
                                       @search="handleSearch">
                        <template #default>
                          <a-input class="input-email" :placeholder="$t('signUp.s_00004')"
                                   @input="$InputEvent.OnlyNumericLetters($event)"
                                   :allow-clear="!showClearIconEmail || !showClearCheckEmail">
                            <template #prefix>
                              <Icon type="ios-mail-outline" size="22" color="#B2B2B2"/>
                            </template>
                          </a-input>
                        </template>
                      </a-auto-complete>
                      <!--  自动完成 -->
                    </a-form-item>
                    <a-form-item name="captcha"
                                 :hasFeedback="showClearIconInvite"
                                 :validate-status="inviteHelp ? 'error' :'success'"
                                 :help="inviteHelp">
                      <template #label>
                        <span>{{ $t('signUp.s_00005') }} <span style="color: grey">({{
                            $t('signUp.s_00006')
                          }})</span></span>
                      </template>
                      <a-input :maxlength="6" class="input-captcha" :placeholder="$t('signUp.s_00009')"
                               @input="$InputEvent.OnlyNumericLetters($event)"
                               v-model:value="formRegist.invitationCode"
                               @change="inputInviteCode" :allow-clear="!showClearIconInvite"/>
                    </a-form-item>
                    <a-form-item>
                      <a-spin :spinning="sendCodeLoading">
                        <a-button class="bottom-pane" style="width: 100%" type="primary" html-type="submit"
                                  @click="nextEmail"
                                  :disabled="disabled">
                          {{ $t('signUp.s_00007') }}
                        </a-button>
                      </a-spin>
                    </a-form-item>
                  </a-form>
                  <div style="text-align: center">
                    {{ $t('signUp.s_00008') }}
                    <a-button type="link" size="small" class="to-login-btn" @click="toLogin">
                      {{ $t('signUp.s_00010') }}
                    </a-button>
                  </div>
                </div>
                <div style="padding: 10px 0 0 0;width: 450px" v-if="step === 2">
                  <div class="yanz-font-1">{{ $t('signUp.s_00011') }}</div>
                  <div class="yanz-font-2">
                    {{ $t('signUp.s_00012') }}:{{ formRegist.email }}, {{ $t('signUp.s_00013') }}。
                  </div>
                  <div class="yanz-input">
                    <code-input
                        @change="completedInput"
                        :masked="false"
                        :fields="6"
                        :fieldWidth="62"
                        :fieldHeight="62"
                        :required="true"
                    />
                  </div>
                  <div class="send-btn">
                    <a-button type="link" :disabled="disabled" :class="disabled ? 'text-gray' : 'text-blue'"
                              size="small" @click="nextEmail">{{ text }}
                    </a-button>
                  </div>
                  <div>
                    <div class="text-red mb-2">{{ loginError }}</div>
                    <a-button class="bottom-pane mt_10" style="width: 100%" type="primary" html-type="submit"
                              @click="emailCheckCode">
                      {{ $t('common.com_000') }}
                    </a-button>
                  </div>
                </div>
                <div style="padding: 2px 0 0 0;" v-if="step === 3">
                  <div class="yanz-font-1">{{ $t('signUp.s_00014') }}</div>
                  <div class="yanz-font-2 mb_10">
                    {{ $t('signUp.s_00015') }}
                  </div>
                  <a-form
                      :model="formRegist"
                      name="basic"
                      :rules="passwordRules"
                      autocomplete="off">
                    <a-form-item name="email">
                      <a-input-password class="input-email" :placeholder="$t('signUp.s_00016')"
                                        @input="$InputEvent.OnlyNumericLetters($event)"
                                        v-no-chinese
                                        @change="changePwdError1"
                                        v-model:value="formRegist.password">
                      </a-input-password>
                      <div class="text-red mb-2">{{ pwdError1 }}</div>
                    </a-form-item>
                    <a-form-item name="captcha">
                      <a-input-password class="input-email" :placeholder="$t('signUp.s_00017')"
                                        @input="$InputEvent.OnlyNumericLetters($event)"
                                        @change="changePwdError2"
                                        v-no-chinese
                                        v-model:value="formRegist.password1"/>
                      <div class="text-red mb-2">{{ pwdError2 }}</div>
                    </a-form-item>
                    <a-form-item :label="$t('signUp.s_00018')">
                      <password :password="formRegist.password" @change="pwdChange"/>
                    </a-form-item>
                    <a-form-item>
                      <div class="text-red mb-2">{{ loginError }}</div>
                      <a-button class="bottom-pane" style="width: 100%" type="primary" html-type="submit"
                                @click="emailRegister" :disabled="pwdError1 !== '' || pwdError2 !== ''">
                        {{ $t('signUp.s_00019') }}
                      </a-button>
                    </a-form-item>
                  </a-form>
                </div>
              </a-tab-pane>
              <a-tab-pane key="2" :tab="$t('signUp.s_00021')">
                <div style="padding: 10px 0 0 0" v-if="phonstep === 1">
                  <a-form
                      layout="vertical"
                      :model="formRegist"
                      name="basic"
                      :rules="phoneRules"
                      autocomplete="off">
                    <a-form-item name="phone"
                                 :has-feedback="showClearIconPhone"
                                 :validate-status="phoneHelp ? 'error' :'success'"
                                 :help="phoneHelp">
                      <a-input class="input-phone" :placeholder="$t('signUp.s_00022')" v-model:value="formRegist.phone"
                               @input="$InputEvent.OnlyNumericLetters($event)"
                               v-in-number
                               @change="onKeyInputPhone"
                               :allow-clear="!showClearIconPhone">
                        <template #prefix>
                          <Vue3CountryIntl
                              schema="popover"
                              :selectedText="$t('signUp.s_00023')"
                              :useChinese="true"
                              :searchInputPlaceholder="$t('common.com_002')"
                              v-model="formRegist.countryCode"
                              v-model:visible="countryView"
                              @change="changeCountry">
                            <Vue3CountryFlag :value="formRegist.iso2" @click="countryView = !countryView">
                              <template v-slot="{country}">
                                <span style="margin-left: 3px;">{{ '+' + country.dialCode }}</span>
                                <Icon type="md-arrow-dropdown"/>
                              </template>
                            </Vue3CountryFlag>
                          </Vue3CountryIntl>
                          <a-divider type="vertical" style="margin-left: -6px;"/>
                        </template>
                      </a-input>
                    </a-form-item>
                    <a-form-item name="captcha"
                                 :hasFeedback="showClearIconInvite"
                                 :validate-status="inviteHelp ? 'error' :'success'"
                                 :help="inviteHelp">
                      <template #label>
                        <span>{{ $t('signUp.s_00005') }} <span style="color: grey">({{
                            $t('signUp.s_00006')
                          }})</span></span>
                      </template>
                      <a-input :maxlength="6" class="input-captcha" :placeholder="$t('signUp.s_00009')"
                               @input="$InputEvent.OnlyNumericLetters($event)"
                               v-model:value="formRegist.invitationCode"
                               @change="inputInviteCode" :allow-clear="!showClearIconInvite"/>
                    </a-form-item>
                    <a-form-item>
                      <a-button class="bottom-pane" style="width: 100%" type="primary" @click="codePhone"
                                :disabled="disabled">
                        {{ $t('signUp.s_00007') }}
                      </a-button>
                    </a-form-item>
                  </a-form>
                  <div style="text-align: center">
                    {{ $t('signUp.s_00008') }}
                    <a-button type="link" size="small" class="to-login-btn" @click="toLogin">{{
                        $t('signUp.s_00010')
                      }}
                    </a-button>
                  </div>
                </div>
                <div style="padding: 10px 0 0 0;width: 450px" v-if="phonstep === 2">
                  <div class="yanz-font-1">{{ $t('signUp.s_00011') }}</div>
                  <div class="yanz-font-2">
                    {{ $t('signUp.s_00012') }}: + {{ formRegist.countryCode }} {{
                      formRegist.phone
                    }},{{ $t('signUp.s_00024') }}。
                  </div>
                  <div class="yanz-input">
                    <code-input
                        @change="completedInput"
                        :masked="false"
                        :fields="6"
                        :fieldWidth="62"
                        :fieldHeight="62"
                        :required="true"
                    />
                  </div>
                  <div class="send-btn">
                    <a-button type="link" :disabled="disabled" :class="disabled ? 'text-gray' : 'text-blue'"
                              size="small" @click="codePhone">{{ text }}
                    </a-button>
                  </div>
                  <div>
                    <div class="text-red mb-2">{{ loginError }}</div>
                    <a-button class="bottom-pane mt_10" style="width: 100%" type="primary" html-type="submit"
                              @click="phoneCheckCode">
                      {{ $t('common.com_000') }}
                    </a-button>
                  </div>
                </div>
                <div style="padding: 2px 0 0 0;" v-if="phonstep === 3">
                  <div class="yanz-font-1">{{ $t('signUp.s_00014') }}</div>
                  <div class="yanz-font-2 mb_10">
                    {{ $t('signUp.s_00015') }}
                  </div>
                  <a-form
                      :model="formRegist"
                      name="basic"
                      :rules="passwordRules"
                      autocomplete="off">
                    <a-form-item name="email">
                      <a-input-password class="input-email" :placeholder="$t('signUp.s_00016')"
                                        @input="$InputEvent.OnlyNumericLetters($event)"
                                        v-no-chinese
                                        @change="changePwdError1"
                                        v-model:value="formRegist.password">
                      </a-input-password>
                      <div class="text-red mb-2">{{ pwdError1 }}</div>
                    </a-form-item>
                    <a-form-item name="captcha">
                      <a-input-password class="input-email" :placeholder="$t('signUp.s_00017')"
                                        @input="$InputEvent.OnlyNumericLetters($event)"
                                        v-no-chinese
                                        @change="changePwdError2"
                                        v-model:value="formRegist.password1"/>
                      <div class="text-red mb-2">{{ pwdError2 }}</div>
                    </a-form-item>
                    <a-form-item :label="$t('signUp.s_00018')">
                      <password :password="formRegist.password" @change="pwdChange"/>
                    </a-form-item>
                    <a-form-item>
                      <div class="text-red mb-2">{{ loginError }}</div>
                      <a-button class="bottom-pane" style="width: 100%" type="primary" html-type="submit"
                                @click="phoneRegister" :disabled="pwdError1 !== '' || pwdError2 !== ''">
                        {{ $t('signUp.s_00019') }}
                      </a-button>
                    </a-form-item>
                  </a-form>
                </div>
              </a-tab-pane>
            </a-tabs>
          </div>
        </div>

        <div class="diccc">
          <div class="customer cursor-pointer" @click="showCusService">
            <div class="font-top">
              {{ $t('common.com_003') }}
            </div>
            <img
                src="../assets/index/cou.png"
                class="cou-img"
                alt=""
                @click="showCusService"
            />
            <div class="font-bom">
              <div>
                <Icon type="ios-headset-outline" size="18"/>
                {{ $t('common.com_003') }}
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- 底部栏 -->
    <BaseFooter></BaseFooter>
  </div>
</template>

<script>
import sendVerifyCode from '@/utils/SendVerifyCode'
import BaseFooter from '@/components/footer/Footer.vue'
import password from '@/components/pwd.vue'
import {
  checkEmail,
  checkEmailCode,
  checkPhone,
  checkPhoneCode,
  checkPhoneExists,
  checkRegisterInvite,
  emailCaptcha,
  getUserInfo,
  registerEmail,
  registerMobile,
  registerVerify
} from '@/api/user'

import { Debounce } from '@/utils/validate'

export default {
  name: 'SignUp',
  components: {
    BaseFooter,
    password
  },
  mixins: [sendVerifyCode],
  data() {
    return {
      completed: false,
      config: require('@/config'),
      year: new Date().getFullYear(),
      activeKey: '1',
      formRegist: {
        email: '',
        iso2: 'us',
        invitationCode: '',
        captcha: '',
        phone: '',
        password: '',
        password1: '',
        countryCode: '1',
      },
      flag: 1,
      color: '',
      countryView: false,
      emailRules: {
        email: [
          {
            required: true,
            message: this.$t('signUp.s_00025')
          },
          {
            pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            message: this.$t('signUp.s_00026'),
            trigger: 'blur'
          }
        ]
      },
      phoneRules: {
        phone: [
          {
            required: true,
            message: this.$t('signUp.s_00027')
          },
        ]
      },
      passwordRules: {
        password: [
          {
            required: true,
            message: this.$t('signUp.s_00028')
          },
        ],
        password1: [
          {
            required: true,
            message: this.$t('signUp.s_00029')
          },
        ],
      },
      showClearCheckEmail: false,
      showClearIconEmail: false,
      showClearIconInvite: false,
      showClearIconPhone: false,
      step: 1,
      phonstep: 1,
      pwdLevel: 0,
      previewVisible: false,
      sendCodeLoading: false,
      emailHelp: '',
      phoneHelp: '',
      inviteHelp: '',
      loginError: '',
      pwdError1: '',
      pwdError2: '',
      options: [],
      emailSuffixOptions: [
        'gmail.com',
        'yahoo.com',
        'hotmail.com',
        'outlook.com',
        'icloud.com',
      ],
    }
  },
  methods: {
    changePwdError1(e) {
      let message = e.target.value
      this.pwdError2 = ''
      if (message !== this.formRegist.password1 && this.formRegist.password1) {
        this.pwdError2 = '两次输入的密码不一致，请重新输入。'
      }
    },
    changePwdError2(e) {
      let message = e.target.value
      this.pwdError2 = ''
      if (message !== this.formRegist.password) {
        this.pwdError2 = '两次输入的密码不一致，请重新输入。'
      }
    },
    handleSearch(val) {
      let res = []
      if (!val || val.indexOf('@') >= 0) {
        res = []
      } else {
        res = this.emailSuffixOptions.map(domain => ({ value: `${val}@${domain}` }))
      }
      this.options = res
    },
    showCusService() {
      let routeUrl = this.$router.resolve({
        path: '/ChatIm',
        query: { //要传的参数 可传多个
          dbMerId: 0
        }
      })
      window.open(routeUrl.href, '_blank')
    },
    changeActive(event) {
      this.activeKey = event
      this.pwdError1 = ''
      this.pwdError2 = ''
    },
    completedInput(e) {
      this.formRegist.captcha = e
      this.loginError = ''
    },
    phoneRegister: Debounce(function () {
      let that = this
      if (!that.formRegist.phone) return that.loginError = this.$t('signUp.s_00030')
      if (!that.formRegist.captcha) return that.loginError = this.$t('signUp.s_00031')
      if (!/^[\w\d]+$/i.test(that.formRegist.captcha)) that.loginError = this.$t('signUp.s_00032')
      if (!that.formRegist.password) return that.loginError = this.$t('signUp.s_00033')
      // 密码复杂度校验
      // 密码必须包括字母和数字，长度6到18位。
      if (!/^(?=.*[A-Za-z])(?=.*\d).{8,}$/i.test(that.formRegist.password)) return that.loginError = this.$t('signUp.s_00034')
      if (!that.formRegist.password1) return that.loginError = this.$t('signUp.s_00035')
      if (that.formRegist.password !== that.formRegist.password1) return that.loginError = this.$t('signUp.s_00036')
      registerMobile({
        'captcha': that.formRegist.captcha,
        'countryCode': '' + that.formRegist.countryCode,
        'password': that.formRegist.password,
        'phone': that.formRegist.phone,
        'invitationCode': that.formRegist.invitationCode
      })
          .then(res => {
            if (res.code === 200) {
              let data = res.data
              this.$store.commit('LOGIN', {
                'token': res.data.token
              })
              if (res.data.isNew && res.data.newPeopleCouponList && res.data.newPeopleCouponList.length !== 0) {
                localStorage.setItem('newGift', JSON.stringify(res.data.newPeopleCouponList))
              } else {
                localStorage.removeItem('newGift')
              }
              that.getUserInfo(data)
            } else {
              that.loginError = res.message
            }
          })
          .catch(res => {
            that.loginError = res.message
          })
    }),
    phoneCheckCode() {
      let that = this
      if (!that.formRegist.captcha) {
        that.loginError = this.$t('signUp.s_00031')
      }
      let data = {
        phone: this.formRegist.phone,
        captcha: that.formRegist.captcha,
        countryCode: that.formRegist.countryCode,
      }
      checkPhoneCode(data)
          .then((res) => {
            if (res.code === 200) {
              that.phonstep = 3
              that.loginError = ''
            } else {
              that.loginError = res.message
            }
          })
          .catch((err) => {
          })
    },
    codePhone: Debounce(function () {
      if (!this.formRegist.phone) {
        this.phoneHelp = '请输入手机号'
        return
      }
      if (!this.showClearIconPhone) {
        this.phoneHelp = '请输入正确的手机号'
        // this.$Message.error(this.$t('signUp.s_00039'))
        return
      }
      if (this.formRegist.invitationCode && !this.showClearIconInvite) {
        this.inviteHelp = '请输入正确的邀请码'
        // this.$Message.error(this.$t('signUp.s_00040'))
        return
      }
      this.phoneHelp = ''
      let param = {
        countryCode: '' + this.formRegist.countryCode,
        phone: this.formRegist.phone
      }
      checkPhoneExists(param)
          .then(res => {
            if (res.code === 200) {
              registerVerify(param)
                  .then(res => {
                    if (res.code === 200) {
                      this.phonstep = 2
                      this.sendCode()
                    } else {
                      this.phoneHelp = res.message
                    }
                  })
            } else {
              this.phoneHelp = res.message
            }
          })
    }),
    // 手机键盘输入实时反馈
    onKeyInputPhone: function (event) {
      if (!this.formRegist.phone) {
        this.phoneHelp = '请输入手机号'
        return
      }
      // 检测手机是否已注册
      let data = {
        phone: this.formRegist.phone,
        countryCode: this.formRegist.countryCode
      }
      checkPhone(data)
          .then(res => {
            if (res.code === 200) {
              this.showClearIconPhone = true
              this.phoneHelp = ''
            } else {
              this.showClearIconPhone = false
              this.phoneHelp = '请输入正确的手机号'
            }
          })
          .catch(res => {
            this.showClearIconPhone = false
            this.phoneHelp = '请输入正确的手机号'
          })
    },
    emailRegister: Debounce(function () {
      let that = this
      if (!that.formRegist.email) return that.loginError = this.$t('signUp.s_00041')
      if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(that.formRegist.email)) return that.loginError = this.$t('signUp.s_00042')
      if (!that.formRegist.captcha) return that.loginError = this.$t('signUp.s_00031')
      if (!that.formRegist.password) that.loginError = this.$t('signUp.s_00033')
      if (!/^(?=.*[A-Za-z])(?=.*\d).{8,}$/i.test(that.formRegist.password)) return that.loginError = this.$t('signUp.s_00034')
      if (!that.formRegist.password1) return that.loginError = this.$t('signUp.s_00043')
      if (that.formRegist.password !== that.formRegist.password1) return that.loginError = this.$t('signUp.s_00036')
      registerEmail({
        email: that.formRegist.email,
        captcha: that.formRegist.captcha,
        password: that.formRegist.password,
        invitationCode: that.formRegist.invitationCode
      })
          .then(res => {
            if (res.code === 200) {
              that.$store.commit('LOGIN', {
                'token': res.data.token
              })
              if (res.data.isNew && res.data.newPeopleCouponList && res.data.newPeopleCouponList.length !== 0) {
                console.log(res.data.newPeopleCouponList)
                localStorage.setItem('newGift', JSON.stringify(res.data.newPeopleCouponList))
              } else {
                localStorage.removeItem('newGift')
              }
              that.getUserInfo(res.data)
              this.loginError = ''
            } else {
              this.loginError = res.message
            }
          })
          .catch(res => {
          })
    }),
    getUserInfo(data) {
      let that = this
      that.$store.commit('SETUID', data.id)
      getUserInfo()
          .then(res => {
            that.$store.commit('UPDATE_USERINFO', res.data)
            return that.$router.replace({ path: '/' })
          })
    },
    pwdChange(event) {
      this.pwdLevel = event
      this.pwdError1 = ''
      if (this.formRegist.password && this.pwdLevel <= 1) {
        this.pwdError1 = '密码必须包含至少1个字母和至少1个数字，且长度不低于8位。'
      }
      this.loginError = ''
    },
    changeCountry(event) {
      this.formRegist.iso2 = event.iso2
      this.formRegist.countryCode = event.dialCode
      this.onKeyInputPhone()
    },
    emailCheckCode() {
      let that = this
      if (!that.formRegist.captcha) {
        that.loginError = this.$t('signUp.s_00044')
      }
      let data = {
        captcha: that.formRegist.captcha,
        email: that.formRegist.email,
      }
      checkEmailCode(data)
          .then((res) => {
            if (res.code !== 200) {
              that.loginError = res.message
            } else {
              that.step = 3
              that.loginError = ''
            }
          })
          .catch((err) => {
          })
    },
    nextEmail() {
      if (!this.formRegist.email) {
        this.emailHelp = '请输入邮箱'
        return
      }
      if (!this.showClearCheckEmail) {
        this.emailHelp = '请输入正确的邮箱地址'
        // this.$Message.error(this.$t('signUp.s_00046'))
        return
      }
      if (!this.showClearIconEmail) {
        this.emailHelp = '邮箱地址已存在'
        // this.$Message.error(this.$t('signUp.s_00047'))
        return
      }
      if (this.formRegist.invitationCode && !this.showClearIconInvite) {
        this.inviteHelp = '请输入正确的邀请码'
        // this.$Message.error(this.$t('signUp.s_00040'))
        return
      }
      this.emailHelp = ''
      this.sendCodeLoading = true
      emailCaptcha(this.formRegist.email)
          .then(res => {
            this.sendCodeLoading = false
            if (res.code !== 200) {
              this.$Message.error(res.message)
            } else {
              this.step = 2
              this.sendCode()
            }
          })
          .catch(err => {
            this.$Message.error(err.message)
          })
    },
    inputInviteCode() {
      let that = this
      if (!that.formRegist.invitationCode) {
        this.inviteHelp = ''
        return
      }
      var codeInvite = that.formRegist.invitationCode
      let para = {
        code: codeInvite
      }
      checkRegisterInvite(para)
          .then((res) => {
            if (!res.data) {
              this.showClearIconInvite = false
              this.inviteHelp = '请输入正确的邀请码'
            } else {
              this.showClearIconInvite = true
              this.inviteHelp = ''
            }
          })
          .catch(err => {
            this.showClearIconInvite = false
            this.inviteHelp = '请输入正确的邀请码'
          })
    },
    // 邮箱键盘输入实时反馈
    onKeyInputEmail() {
      let that = this
      if (!that.formRegist.email) {
        this.emailHelp = ''
        return
      }
      // 校验邮箱格式
      if (!/^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/i.test(that.formRegist.email)) {
        this.showClearIconEmail = false
        this.showClearCheckEmail = false
        this.emailHelp = '请输入正确的邮箱地址'
        return
      }
      this.showClearCheckEmail = true
      // 检测邮箱是否已注册
      checkEmail(that.formRegist.email)
          .then(res => {
            if (!res.data) {
              this.showClearIconEmail = false
              this.emailHelp = '邮箱地址已存在'
            } else {
              this.showClearIconEmail = true
              this.emailHelp = ''
            }
          })
          .catch(res => {
            this.emailHelp = '邮箱地址已存在'
            this.showClearIconEmail = false
          })
    },
    toLogin() {
      this.$router.push({
        path: '/login',
      })
    },
  },
  mounted() {
    if (window.innerHeight > 1050) {
      document.querySelector('.sign-up').style.height = (window.innerHeight - 330) + 'px'
    } else {
      document.querySelector('.sign-up').style.height = '750px'
    }
  }
}
</script>
<style scoped lang="scss">
.item-sert {
  border-radius: 10rpx;
  background: #fff;
  margin-bottom: 10rpx;

  // 安全等级
  .securitylevel {
    width: 100%;
    height: 80rpx;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24rpx;

    .text1 {
      width: 150rpx;
      height: 30rpx;
      // font-weight: 600;
    }

    .text2 {
      display: inline-block;
      width: 50rpx;
      height: 30rpx;
      margin-left: 10rpx;
    }

    .item2 {
      display: inline-block;
      width: calc(480rpx / 3);
      height: 30rpx;
      background-color: #ddd;
      margin-left: 10rpx;
    }
  }
}

.send-btn {
  display: flex;
  justify-content: flex-end;
}

.yanz-font-1 {
  font-family: Source Han Sans;
  font-size: 18px;
  font-weight: normal;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: 0px;
  color: #3D3D3D;
}

.yanz-font-2 {
  margin-top: 10px;
  font-family: Source Han Sans;
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  display: flex;
  align-items: center;
  letter-spacing: 0px;
  color: #999999;
}

.yanz-input {
  margin-top: 10px;
}

:deep(.vue3-yn-country-flag .country-flag-img) {
  width: 30px;
}

:deep(.ant-input-affix-wrapper .ant-input-clear-icon) {
  color: red !important;
}

.input-email {
  width: 100%;
  height: 50px;
  border-radius: 12px;

  border: 1px solid rgba(102, 102, 102, 0.35);
}

.input-captcha {
  width: 100%;
  height: 50px;
  border-radius: 12px;

  border: 1px solid rgba(102, 102, 102, 0.35);
}

.bottom-pane {
  width: 100%;
  height: 50px;
  border-radius: 12px;
  opacity: 1;

  background: #269FFA;
}

.input-phone {
  width: 100%;
  height: 50px;
  border-radius: 12px;

  border: 1px solid rgba(102, 102, 102, 0.35);

}

.login-center {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px;

  .diccc {
    height: 473px;
    margin-left: 142px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;

    .customer {
      position: relative;
      width: 136px;
      height: 204px;
      border-radius: 10px;
      opacity: 1;
      box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.1);

      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: center;

      .font-top {
        padding: 10px 0;
        font-family: Source Han Sans;
        font-size: 20px;
        font-weight: bold;
        line-height: 25px;
        text-align: center;
        letter-spacing: 0px;
        color: #269FFA;
      }

      .cou-img {
        width: 91.81px;
        height: 91.31px;
      }

      .font-bom {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 42px;
        border-radius: 0px 0px 10px 10px;
        opacity: 1;
        background: #269FFA;

        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;

        font-family: Source Han Sans;
        font-size: 14px;
        font-weight: normal;
        line-height: 25px;
        text-align: center;
        letter-spacing: 0px;
        color: #FFFFFF;
      }
    }

  }

  .login-container {
    width: 992px;
    height: 473px;
    border-radius: 24px;
    border: 1px solid #F4F4F4;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    .container-right {
      width: 510px;
      height: 473px;
      padding: 20px 50px 0 50px;
    }
  }
}

.top-content {
  width: 100%;
  height: 80px;
  position: relative;
  z-index: 1;

  box-shadow: 0 1px 1px #ddd;

  .to-login {
    font-family: HarmonyOS Sans;
    font-size: 16px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: 0px;
    color: #269FFA;

    button {
      color: #269FFA;
    }
  }

  .logo-img {
    max-width: 150px;
    cursor: pointer;
    margin-right: 32px;
  }

  .logo-box {
    width: 80%;
    max-width: 1200px;
    height: 80px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;

    .left {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: nowrap;

      .register {
        font-family: HarmonyOS Sans;
        font-size: 16px;
        font-weight: normal;
        line-height: normal;
        letter-spacing: 0px;
        color: #999999;
      }

      img {
        width: 150px;
        cursor: pointer;
      }

      div {
        font-size: 20px;
      }
    }
  }
}

.logo-box {
  width: 600px;
  height: 80px;
  margin: 0 auto;
  display: flex;
  align-items: center;

  img {
    width: 150px;
    cursor: pointer;
  }

  div {
    font-size: 20px;
    margin-top: 10px;
  }
}

.verify-con {
  position: absolute;
  left: 140px;
  top: 80px;
  z-index: 10;
}

.other-login {
  margin: 0 auto;

  .ivu-icon {
    font-size: 24px;
  }
}

.regist {
  display: flex;
  justify-content: flex-end;
  margin-top: -10px;

  span {
    margin-left: 10px;

    &:hover {
      cursor: pointer;
      color: #F31947;
    }
  }
}

.foot {
  position: fixed;
  bottom: 4vh;
  width: 368px;
  left: calc(50% - 184px);
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;

  .help {
    margin: 0 auto;
    margin-bottom: 1vh;
    width: 60%;

    .item {
      color: rgba(0, 0, 0, 0.45);
    }

    :hover {
      color: rgba(0, 0, 0, 0.65);
    }
  }
}
</style>
